import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Training from '../../components/Training';

function PrivateTraining() {
  return (
    <>
      <Helmet>
        <title>Private Karate Training, Personal Martial Arts Tuition | Hills Combat</title>
      </Helmet>
      <PageHeader name="Private Karate Training" />
      <Training name="Private Karate Tuition" image="/images/gallery/private-karate-tuition.jpg">
        <>
          <p className="excerpt">
            At Hills Combat, we offer private Karate and self-defence tuition.
            Private sessions are available at private venues and available to
            members and the general public.
          </p>
          <p>
            All private Karate and self-defence lessons will be designed to suit
            you and your goals. Our instructors will tailor the sessions based
            on your grade, experience, age, fitness and goals.
          </p>

          <br />

          <p>Benefits of private tuition:</p>

          <hr />
          <ul className="list-styled">
            <li>Achieve goals quicker</li>
            <li>Get individual expert advice</li>
            <li>Boost motivation</li>
            <li>Flexible schedule &amp; format</li>
          </ul>
          <p>
            To view our prices, please see the
            {' '}
            <Link to="/pricing" title="pricing">pricing</Link>
            {' '}
            page.
          </p>
        </>
      </Training>
    </>
  );
}

export default PrivateTraining;
