import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from '../components/Slider';
import ContactInfo from '../components/ContactInfo';
import TrainingList from '../components/TrainingList';
import InstructorList from '../components/InstructorList';

function Home() {
  return (
    <>
      <Helmet>
        <title>Karate Ashby de-la-Zouch, Martial Arts, Self Defence | Hills Combat</title>
        <meta
          name="keywords"
          content="karate classes ashby-de-la-zouch,  martial arts ashby-de-la-zouch, self defence"
        />
        <meta
          name="description"
          content="Hills Combat is a karate self defence school based in Packington, Ashby-de-la-Zouch."
        />
      </Helmet>
      <Slider />
      <ContactInfo />
      <TrainingList />
      <InstructorList />
    </>
  );
}

export default Home;
