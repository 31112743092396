import { useState } from 'react';
import axios from 'axios';
import { useForm, SubmitHandler } from 'react-hook-form';
import { verifyUrl } from '../components/MtCaptcha';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type Inputs = {
  name: string,
  email: string,
  phone: string,
  subject: string
  message: string
};

function useContact() {
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [unknownError, setUnknownError] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async ({
    name, phone, email, subject, message,
  }) => {
    const body = JSON.stringify({
      name,
      phone,
      email,
      subject,
      message,
      'mtcaptcha-verifiedtoken': window.mtcaptcha.getVerifiedToken(),
    });

    try {
      await axios.post(verifyUrl, body);
      setFormSuccessful(true);
      setUnknownError(false);
      window.mtcaptcha.resetUI();
    } catch (error) {
      setFormSuccessful(false);
      setUnknownError(true);
      window.mtcaptcha.resetUI();
    }
  };

  return {
    errors,
    register,
    unknownError,
    formSuccessful,
    submitForm: handleSubmit(onSubmit),
  };
}

export default useContact;
