import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';
import useContact, { EMAIL_REGEX } from '../hooks/useContact';

function Contact() {
  const {
    register, submitForm, errors, unknownError, formSuccessful,
  } = useContact();

  return (
    <>
      <Helmet>
        <title>Contact Us | Hills Combat</title>
      </Helmet>
      <PageHeader name="Contact Us" />
      <section className="ds s-py-60 s-py-md-90 c-gutter-60">
        <div className="container">
          <div className="row">
            <div className="divider-70 d-none d-xl-block" />
            <div className="col-lg-12 col-xl-6">
              <h4 className="text-left contact-h4">
                Contact Form
              </h4>
              <form className="contact-form c-mb-20 c-gutter-20" method="post" onSubmit={submitForm}>
                {formSuccessful && (
                  <div className="alert alert-success">
                    <p>Thank you for contacting us</p>
                  </div>
                )}

                {unknownError && (
                  <div className="alert alert-danger">
                    <p>Oops, there was an error</p>
                  </div>
                )}

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group has-placeholder">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        defaultValue=""
                        {...register('name', { required: true })}
                      />

                      {errors.name && <p className="text-danger">Please enter your name</p>}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group has-placeholder">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        defaultValue=""
                        {...register('email', { required: true, pattern: EMAIL_REGEX })}
                      />

                      {errors.email && <p className="text-danger">Please enter a valid email</p>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group has-placeholder">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone number"
                        defaultValue=""
                        {...register('phone')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group has-placeholder">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        defaultValue=""
                        {...register('subject')}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group has-placeholder">
                      <textarea
                        id="message"
                        className="form-control"
                        placeholder="Message"
                        defaultValue=""
                        {...register('message', { required: true })}
                      />

                      {errors.message && <p className="text-danger">Please enter the message</p>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="mtcaptcha" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 text-left">
                    <div className="form-group">
                      <button type="submit" id="contact_form_submit" name="contact_submit" className="btn btn-maincolor">
                        Send Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="ds ms page_map map-small mt-50 mt-xl-0">
                <iframe
                  title="PACKINGTON MEMORIAL HALL, ASHBY-DE-LA-ZOUCH, LE65 1WJ"
                  width="100%"
                  height="460"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=PACKINGTON%20MEMORIAL%20HALL%20ASHBY-DE-LA-ZOUCH,%20LE65%201WJ&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
