import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingAshby() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Ashby de-la-Zouch, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate ashby-de-la-zouch, children's self defence ashby-de-la-zouch" />
        <meta name="description" content="Hills Combat Children's Karate Ashby de-la-Zouch provides karate classes for ages 4-15 in Ashby de-la-Zouch. Join one of our fun martial arts self-defence classes today!" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Ashby de-la-Zouch" />
      <Training
        club="ashby"
        name="Children&apos;s Karate Ashby de-la-Zouch"
        image="/images/gallery/childrens-karate-ashby-de-la-zouch.jpg"
      >
        <>
          <p className="excerpt">
            Hills Combat
            {' '}
            <strong>Children&apos;s Karate Ashby de-la-Zouch</strong>
            {' '}
            offers self-defense and karate classes at the Packington Memorial Hall in
            Ashby de-la-Zouch. The club caters to students aged 4-15 and is divided
            into two groups based on age.
          </p>

          <p>
            The younger group session (ages 4-7) is designed to be an introduction to karate and
            self-defense. Students in this group have boundless energy and often have
            aspirations of becoming ninja heroes. At Hills Combat, we channel that
            energy into something fun and productive with long-term benefits, such as
            respect, discipline, and conflict resolution.
          </p>

          <p>
            The older group session (ages 8-15) is also an energetic and fun class.
            We teach all students the fundamentals of self-defense, karate, and, most importantly,
            how to stay safe. Our classes also focus on conflict resolution and anti-bullying
            skills that are essential for this age group.
          </p>

          <hr />
          <h6 className="excerpt">
            All children will learn:
          </h6>
          <hr />

          <ul className="list-styled">
            <li>Respect</li>
            <li>Discipline</li>
            <li>Conflict resolution</li>
            <li>Kicking</li>
            <li>Punching</li>
            <li>Blocking</li>
            <li>Kata</li>
            <li>Sparring</li>
            <li>Punch &amp; kick bag drills</li>
          </ul>

          <p>
            All of the above will be taught at grade levels and will become more advanced as the
            student progresses. More information on the
            {' '}
            <Link to="/karate/grading-system">Karate Grading System</Link>
            {' '}
            can be found on our website, including the Kyu grades and frequently asked questions
            regarding gradings.
          </p>

          <p>
            Karate provides a host of benefits for children, not just in terms of physical fitness
            but also in terms of developing self-confidence, self-discipline, and focus.
            It&apos;s also a great way to build social skills and make new friends.
          </p>

          <p>
            Hills Combat Children&apos;s Karate Ashby de-la-Zouch is proud to be a member of the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation</Link>
            {' '}
            (EKO). As a member, all our students have access to a
            recognised grade authority, as well as many other benefits.
            {' '}
            <Link to="/instructors/sensei-mike-hills" title="Sensei Mike">Sensei Mike</Link>
            {' '}
            and
            {' '}
            <Link to="/instructors/sensei-rob-hills" title="Sensei Rob">Sensei Rob</Link>
            {' '}
            are EKO-approved instructors with a wealth of experience
            working with young students. Everyone is welcome at Hills Combat,
            so please do not hesitate to try out a class for free.
          </p>

          <p className="excerpt">
            Hills Combat Children&apos;s Karate in Ashby de-la-Zouch takes place every Thursday at the Packington Memorial Hall,
            LE65 1WJ, with the beginners&apos; class scheduled from 5:00 pm to 6:00 pm and the advanced class following from 6:00 pm to 7:00 pm.
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingAshby;
