import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import PageHeader from '../../../components/PageHeader';
import useLoginVerification from '../../../hooks/useLoginVerification';
import Unauthorised from '../../../components/Training/Online/Unauthorised';

function Sitemap() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Members Dashboard | Hills Combat</title>
      </Helmet>

      <PageHeader name="Members Area" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
        <section className="ds s-py-60 s-py-md-90 s-overlay mobile-overlay about-section">
          <div className="container">
            <div className="row d-flex justify-content-end">
              <div className="divider-70 d-none d-xl-block" />
              <div className="col-md-12 col-xl-6 ">
                <h3 className="special-heading text-lg-left">
                  Members Area
                </h3>
                <div className="divider-40 d-none d-xl-block" />
                <p>
                  Welcome to the Hills Combat members area. We are excited to be offering
                  this online service because it&apos;ll allow all our students to practice at
                  home by having access to online training videos.
                  As the students progress through their grading ranks,
                  we&apos;ll add more training content to support their training.
                </p>
                <p>
                  Below are the first two katas that each student will need to learn.
                  If you have any questions, feel free to contact us at hello@hillscombat.uk.
                </p>
                <p>
                  Keep training hard.
                  <br />
                  Rob &amp; Mike
                </p>
                <div className="divider-40 d-none d-xl-block" />
                <h4 className="text-lg-left">
                  Katas
                </h4>
                <div className="divider-40 d-none d-xl-block" />
                <ul>
                  <li>
                    <Link to="/kata/heian-shodan">Heian Shodan (8th Kyu)</Link>
                  </li>
                  <li>
                    <Link to="/kata/heian-nidan">Heian Nidan (7th Kyu)</Link>
                  </li>
                  <li>
                    <Link to="/kata/heian-sandan">Heian Sandan (6th Kyu)</Link>
                  </li>
                </ul>
                <div className="divider-40 d-none d-xl-block" />
                <h4 className="text-lg-left">
                  Techniques
                </h4>
                <div className="divider-40 d-none d-xl-block" />
                <ul>
                  <li>
                    <Link to="/techniques/mawashi-geri">Mawashi Geri (roundhouse kick)</Link>
                  </li>
                  <li>
                    <Link to="/techniques/gyakuzuki">Gyakuzuki (Reverse Punch)</Link>
                  </li>
                  <li>
                    <Link to="/techniques/shuto-uke">Shuto Uke i Kokutsu Dachi (open hand block in back stance)</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Sitemap;
