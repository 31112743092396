import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import Instructor from '../../components/Instructor';

function MikeHills() {
  return (
    <>
      <Helmet>
        <title>Sensei Mike Hills | Hills Combat</title>
      </Helmet>
      <PageHeader name="Sensei Mike" />
      <Instructor firstName="Mike" lastName="Hills">
        <p className="excerpt text-lg-left">
          Meet one of the experienced instructors at Hills Combat. Mike Hills started
          practising Shotokan Karate in 1990 to keep fit and to enjoy some quality time
          with his young son Robert whilst teaching him discipline and improving his focus.
        </p>
        <p className="text-lg-left">
          Over the years of twice-weekly sessions Mike, after hard work and dedication,
          received the appropriate gradings eventually achieving his black belt qualification
          (1st Dan) in September 1996. Throughout the years Mike continued to train leading to
          his 2nd Dan in December 1999. He decided that he wanted to teach a children’s and
          adult Shotokan karate club (EKO supported) in his local area. This ran successfully
          with the support of the EKO until 2008. Throughout this time, Mike proudly received
          his 3rd Dan Shotokan karate qualification in April 2003.
          <br />
          <br />
          Mike was also awarded very prestigious awards for a few years beginning in March
          1998 (EKO National referee) as he worked as a referee for the EKO Championships
          for several years.
          <br />
          <br />
          In 2023, Mike&apos;s dedication to the art of Karate Do was honored with the presentation of his 4th Dan black
          belt by EKO chairman Dave Sharkey, recognising his remarkable progress achieved through diligent study.
        </p>
      </Instructor>
    </>
  );
}

export default MikeHills;
