import React from 'react';
import { Link } from 'react-router-dom';

interface IInstructor {
  image: string
  name: string
  grade: string
  link: string
}

function Instructor({
  image, name, grade, link,
}: IInstructor) {
  return (
    <div className="instructor">
      <div className="vertical-item box-shadow content-padding text-center">
        <div className="item-media">
          <img src={image} alt={name} />
          <div className="media-links">
            <Link className="abs-link" title={name} to={link}> </Link>
          </div>
        </div>
        <div className="item-content">
          <h5>
            <Link to={link} title={name}>{name}</Link>
          </h5>
          <p className="small-text color-main">{grade}</p>
        </div>
      </div>
    </div>
  );
}

function InstructorList() {
  return (
    <section className="ds s-py-60 s-py-md-90 team-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="special-heading text-center">OUR INSTRUCTORS</h3>
            <div className="instructors">
              <Instructor image="/images/gallery/sensei-mike-hills.jpg" name="Sensei Mike" grade="4th Dan" link="/instructors/sensei-mike-hills" />
              <Instructor image="/images/gallery/sensei-rob-hills.jpg" name="Sensei Rob" grade="2nd Dan" link="/instructors/sensei-rob-hills" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InstructorList;
