import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';
import useLogin from '../hooks/useLogin';

function Contact() {
  const {
    register, submitForm, errors, unknownError,
  } = useLogin();

  return (
    <>
      <Helmet>
        <title>Members Login | Hills Combat</title>
      </Helmet>
      <PageHeader name="Members Login" />
      <section className="ds s-py-60 s-py-md-90 c-gutter-60">
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="divider-70 d-none d-xl-block" />
            <div className="col-lg-12 col-xl-6">
              <h4 className="text-left contact-h4">
                Members Login
              </h4>
              <form className="contact-form c-mb-20 c-gutter-20" method="post" onSubmit={submitForm}>
                {unknownError && (
                  <div className="alert alert-danger">
                    <p>Oops, there was an error logging in</p>
                  </div>
                )}

                <div className="row">

                  <div className="form-group has-placeholder">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      defaultValue=""
                      {...register('username', { required: true })}
                    />

                    {errors.username && <p className="text-danger">Please enter your username</p>}
                  </div>

                  <div className="form-group has-placeholder">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      defaultValue=""
                      {...register('password', { required: true })}
                    />

                    {errors.password && <p className="text-danger">Please enter your password</p>}
                  </div>
                </div>

                <div className="row" style={{ paddingLeft: '3px' }}>
                  <div className="col-sm-12">
                    <div className="mtcaptcha" />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group">
                    <button type="submit" id="login_form_submit" name="contact_submit" className="btn btn-maincolor" style={{ width: '100%' }}>
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
