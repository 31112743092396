import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingBurntwood() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Burntwood, Children&apos;s Martial Arts
        </title>
        <meta name="keywords" content="children's karate burntwood, children's martial arts burntwood" />
        <meta name="description" content="Hills Combat Children's Karate Burntwood offers karate classes for children aged 4-15. Try one of our karate classes today!" />
      </Helmet>
      <PageHeader name="Children's Karate Burntwood" />
      <Training
        club="burntwood"
        name="Children's Karate Burntwood"
        image="/images/gallery/childrens-karate-burntwood.jpg"
      >
        <>
          <p className="excerpt">
            Hills Combat
            {' '}
            <strong>Children&apos;s Karate Burntwood</strong>
            {' '}
            provides karate and martial art classes at the Chase Terrace Scout Hall in Burntwood.
            The club is for students aged 4-15, all grades and experiences.
          </p>
          <p>
            As part of our club, the student will soon make friends and will begin
            to feel a sense of belonging amongst their karate buddies. Also,
            the children develop more confidence through coming to the class
            every week, doing kick bag work, keeping fit, learning Katas
            (sequence of moves) partnering up with each other alternately
            to utilise their new karate skills. It is the groundwork for
            the competitions that they will be encouraged to eventually enter.
          </p>
          <p>
            Mike
            {' '}
            <em>(Sensei at Hills Combat Children&apos;s Karate Burntwood)</em>
            {' '}
            has great experience in being a Karate Instructor working
            with young people. All children are made to feel welcome
            at Hills Combat so do not hesitate to come for a free trial.
            You have nothing to lose!
          </p>
          <hr />
          <h6 className="excerpt">
            Our Burntwood Students learn:
          </h6>
          <hr />
          <ul className="list-styled">
            <li>Kicking</li>
            <li>Punching</li>
            <li>Blocking</li>
            <li>Kata</li>
            <li>Sparring</li>
            <li>Punch &amp; kick bag drills</li>
            <li>Respect</li>
            <li>Discipline</li>
            <li>Conflict resolution</li>
          </ul>
          <p>
            Each student will train based on their grade and experience. As they progress
            through the
            {' '}
            <Link to="/karate/grading-system">Karate Grading System</Link>
            , they&apos;ll learn more advanced techniques and skills.
            Furthermore, Hills Combat Children&apos;s Karate Burntwood is a proud member of the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation</Link>
            .
          </p>
          <p>
            Should you require more information about our Karate classes in Burntwood,
            feel free to call Mike on
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            .
            He&apos;ll be more than happy to answer any questions that you may have.
          </p>
          <p className="excerpt">
            Hills Combat Children&apos;s Karate Burntwood is on Mondays from 5:45 pm to 6-45pm
            at the 1st Chase Terrace Scouts, 99 Ironstone Rd, Chase Terrace, Burntwood WS7 1LL.
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingBurntwood;
