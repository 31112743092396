import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Loader from '../../../../components/Loader';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function HeianSandan() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Heian Sandan | The Kata in practice | Hills Combat</title>
      </Helmet>

      <PageHeader name="Heian Sandan" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
      <OnlineTraining
        defaultVideo="/videos/kata/heian-sandan-front-view.mp4"
        title="Heian Sandan"
        subTitle="Peaceful Mind - Third Level"
        videos={[
          {
            src: '/videos/kata/heian-sandan-front-view.mp4',
            image: '/images/members-area/kata/heian-sandan-front-view.jpg',
            title: 'Heian Sandan (front view)',
          },
          {
            src: '/videos/kata/heian-sandan-rear-view.mp4',
            image: '/images/members-area/kata/heian-sandan-rear-view.jpg',
            title: 'Heian Sandan (rear view)',
          },
        ]}
      >
        <>
          <p>
            <strong>1 - Hidari chudan uchi-ude-uke:</strong>
            {' '}
            Turn the head to the left and move the left foot to the left in the kokutsu-dachi rear position while
            blocking the inner edge of the left forearm middle level (uchi-ude-uke), the right fist in hikite.
            Foot movement, kokutsu-dachi transition, and blocking must be synchronized. The bust is in profile.
          </p>

          <p>
            <strong>2 - Hidari gedan-barai / Migi chudan uchi-ude-uke:</strong>
            {' '}
            Group the right leg on the left leg, feet together, to stand up in heisoku-dachi.
            Make a low block (gedan-barai) left, and a middle right lock from inside to outside (uchi-ude-uke).
            Knees stay slightly bent.
            The double circular movement of the fists makes them close to the body, the left fist passing inside the
            right elbow. The spacing of the arms in the final position is equal to the width of the shoulders.
            The elbows serve as a pivot and must move as little as possible; during the whole movement,
            they remain distant from the trunk by a fist-width.
          </p>

          <p>
            <strong>3 - Hidari chudan uchi-ude-uke / Migi gedan-barai:</strong>
            {' '}
            On the spot, invert the arms.

            Make a low blocking (gedan-barai) right, and a middle blocking left from inside to outside (uchi-ude-uke).
            The knees remain slightly folded.
            The double circular movement of the fists makes them close to the body, the right fist passing inside the left elbow.
            Times 2 and 3 are chained in a single sensation. The abdominals are contracted, the shoulders do not move.
          </p>

          <p>
            <strong>4 - Migi chudan uchi-ude-uke:</strong>
            {' '}
            Turn the head to the right and rotate 90° on the left foot by moving the right foot to switch to kokutsu-dachi,
            while blocking the internal cutting edge of the right forearm level medium (uchi-ude-uke), the left fist in hikite.
            Foot movement, kokutsu-dachi transition, and blocking must be synchronized. The bust is in profile.
          </p>

          <p>
            <strong>5 - Hidari chudan uchi-ude-uke / Migi gedan-barai:</strong>
            {' '}
            Group the left leg on the right leg, feet together, to stand up in heisoku-dachi.
            Make a low block (gedan-barai) right, and a middle left block from inside to outside (uchi-ude-uke).
            Knees stay slightly bent.
            The double circular movement of the fists makes them close to the body, the right fist passing inside the left elbow.
            The spacing of the arms in the final position is equal to the width of the shoulders.
            The elbows serve as a pivot and must move as little as possible; during the whole movement, they remain distant from the trunk by a fist-width.
          </p>

          <p>
            <strong>6 - Hidari gedan-barai / Migi chudan uchi-ude-uke:</strong>
            On the spot, invert the arms.
            Do a low blocking (gedan-barai) left, and a middle right block from the inside out (uchi-ude-uke).
            The knees stay slightly bent.
            The double circular movement of the fists makes them close to the body, the left fist passing inside the right elbow.
            Times 5 and 6 are chained in a single sensation. The abdominals are contracted, the shoulders do not move.
          </p>

          <p>
            <strong>7 - Hidari chudan morote-uke: </strong>
            {' '}
            Turn the head to the left and rotate 90° on the right foot by moving the left foot to switch to kokutsu-dachi in the main axis.
            Bet with a medium level double lock. The left arm performs the parry, the right fist, knuckles up, reinforces the inside of the left elbow (morote-uke).
          </p>

          <p>
            <strong>8 - Migi chudan nukite-tate-zuki / Hidari osae-uke:</strong>
            {' '}
            Advance the right foot on the same axis, in zenkutsu-dachi with a vertical spike (tate-nukite) of the right hand middle level,
            in a straight line over the left hand that you close in front of you during the passage forward (osae-uke).
          </p>

          <p>
            <strong>9 - Hidari chudan tettsui-yoko-uchi:</strong>
            {' '}
            Exercise a twist of your right arm in your back.
            Swivel on your right foot by moving the left foot to 180° to finish in kiba-dachi position, still in the main axis.
            At the same time, hammer with a medium left iron hammer (yoko-tettsui-uchi).
          </p>

          <p>
            <strong>10 - Migi chudan o&iuml;-zuki:</strong>
            Advance the right foot forward in zenkutsu-dachi striking with a direct punch to the plexus (o&iuml;-zuki).
          </p>

          <hr />
          <p><strong>KIA&Iuml; !!!</strong></p>
          <hr />

          <p>
            <strong>11 - Ryoken-koshi-kamae:</strong>
            {' '}
            Change of rhythm. Raise up and slowly rotate 180 degrees backward, bringing the left foot back to the right foot.
            Stabilize yourself in heisoku-dachi position. The two fists (ryoken) are placed firmly on the hips, in ryoken-koshi-kamae posture.
          </p>

          <p>
            <strong>12 - Migi yoko-fumikomi / Migi yoko-empi-uchi:</strong>
            {' '}
            Pivot on the left foot 90° to the left, raising the right knee very high, fists on the hips. Fold the right foot into power (yoko-fumikomi) ending the pivot and arriving in kiba-dachi.
            The right elbow strikes forward at the same time (yoko-empi-uchi).
          </p>

          <p>
            <strong>13 - Migi jodan uraken-tate-uchi:</strong>
            On the spot strike circularly with the back of the right fist in a vertical plane (tate-uraken-uchi), before bringing the right fist to the belt.
          </p>

          <p>
            <strong>14 - Hidari yoko-fumikomi / Hidari yoko-empi-uchi:</strong>
            {' '}
            Pivot on the right foot 180° to the right by raising very high the left knee, fists on the hips.
            Close the left foot in power (yoko-fumikomi) ending the pivot and arriving in kiba-dachi. The left elbow strikes forward at the same time (yoko-empi-uchi).
          </p>

          <p>
            <strong>15 - Hidari jodan uraken-tate-uchi:</strong>
            {' '}
            On the spot, strike circularly with the back of the left fist in a vertical plane (tate-uraken-uchi), before bringing the left fist to the belt.
          </p>

          <p>
            <strong>16 - Migi yoko-fumikomi / Migi yoko-empi-uchi:</strong>
            {' '}
            Pivot on the left foot 180° to the left by raising very high the right knee, fists with the hips.
            Fold the right foot into power (yoko-fumikomi) ending the pivot and arriving in kiba-dachi. The right elbow strikes forward at the same time (yoko-empi-uchi).
          </p>

          <p>
            <strong>17 - Migi jodan uraken-tate-uchi:</strong>
            {' '}
            On the spot, strike circularly with the back of the right fist in a vertical plane (tate-uraken-uchi), before bringing the right fist to the belt.
          </p>

          <p>
            <strong>18 - Hidari tate-shuto-uke:</strong>
            {' '}
            On the spot, execute very slowly and with concentration a chudan blocking of the sword of the hand (shuto-uke), the left fist slowly returning in hikite. Inhale during the whole movement.
          </p>

          <p>
            <strong>19 - Hidari chudan oï-zuki:</strong>
            {' '}
            Advance the left foot forward in zenkutsu-dachi by striking with a direct punch to the plexus (oï-zuki) left.
          </p>

          <p>
            <strong>20 - Migi jodan tate-zuki / Hidari ushiro-empi-uchi:</strong>
            {' '}
            Bring the right leg to the left in the small position kiba-dachi.
            Perform a fast rotation on the right foot of 180° to finish in the position kiba-dachi.
            At the same time, give a right punch turning over your left shoulder high (tate-zuki) and send a left elbow
            back (ushiro-empi-uchi) simultaneously. The right hand attacks the opponent&apos;s head behind you, and the left fist attacks the hip.
          </p>

          <p>
            <strong>21 - Migi jodan tate-zuki / Hidari ushiro-empi-uchi:</strong>
            {' '}
            Slightly move the position to the right, keeping the same position kiba-dachi.
            Run a left punch turning over your right shoulder high level (tate-zuki) and send a straight back elbow (ushiro-empi-uchi) simultaneously.
            The left hand attacks the opponent&apos;s head behind you, and the right fist attacks the hip.
          </p>

          <hr />
          <p><strong>KIA&Iuml; !!!</strong></p>
          <hr />
        </>
      </OnlineTraining>
      )}
    </>
  );
}

export default HeianSandan;
