import React, { ReactNode } from 'react';
import Contact from './Contact';
import Accordion from './Accordion';

interface ITraining {
  club?: 'ashby' | 'burntwood' | 'tamworth'
  name: string
  image: string
  children: ReactNode
}

function Training({
  image, name, club, children,
}: ITraining) {
  return (
    <section className="ds s-pt-60 s-pb-0 s-py-md-90 c-gutter-60 c-mb-40">
      <div className="container">
        <div className="row">
          <div className="d-none d-lg-block divider-70" />
          <div className="col-md-7">
            <article
              className="vertical-item content-padding post type-event status-publish format-standard has-post-thumbnail"
            >
              <div className="item-media post-thumbnail">
                <img src={image} alt={name} />
              </div>
              <div className="item-content">
                <div className="entry-content">
                  { children }
                </div>
              </div>
            </article>
          </div>

          <div className="col-md-5">
            <Accordion club={club} />
            <Contact club={club} />
          </div>
        </div>
      </div>
    </section>
  );
}

Training.defaultProps = {
  club: null,
};

export default Training;
