import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMapMarker, faPhone } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
      <section className="s-py-20 s-py-lg-50 c-my-40 c-my-lg-15 cs s-parallax s-overlay contact-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 text-center">
              <div className="icon-box icon-absolute text-center">
                <div className="icon-styled">
                  <FontAwesomeIcon icon={faPhone as any} />
                </div>
                <span>CALL US TODAY</span>
                <h5>+447930 339609 | +447860 465577</h5>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="icon-box icon-absolute text-center">
                <div className="icon-styled">
                  <FontAwesomeIcon icon={faMapMarker as any} />
                </div>
                <span>Burntwood</span>
                <h5>MON 5:45PM - 6:45PM</h5>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="icon-box icon-absolute text-center">
                <div className="icon-styled">
                  <FontAwesomeIcon icon={faMapMarker as any} />
                </div>
                <span>TAMWORTH</span>
                <h5>WED 7:00PM - 8:00PM</h5>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="icon-box icon-absolute text-center">
                <div className="icon-styled">
                  <FontAwesomeIcon icon={faMapMarker as any} />
                </div>
                <span>ASHBY-DE-LA-ZOUCH</span>
                <h5>THUR 5:00PM - 7:00PM</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page_copyright ds s-py-10 s-py-md-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="divider-20 d-none d-lg-block" />
            <div className="col-md-12 text-center">
              <div className="get-social">
                <a href="https://www.facebook.com/hillscombat/" target="_blank" className="icon-styled" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/hillscombat/" target="_blank" className="icon-styled" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
              <p>
                &copy; Hills Combat
                <span className="copyright_year">{` ${new Date().getFullYear()}`}</span>
              </p>
              <p>
                <Link to="/childrens-karate-ashby-de-la-zouch">Ashby-de-la-Zouch</Link>
                {' '}
                |
                {' '}
                <Link to="/childrens-karate-tamworth">Tamworth</Link>
                {' '}
                |
                {' '}
                <Link to="/childrens-karate-measham">Measham</Link>
                {' '}
                |
                {' '}
                <Link to="/childrens-karate-moira">Moira</Link>
                {' '}
                |
                {' '}
                <Link to="/childrens-karate-swadlincote">Swadlincote</Link>
                {' '}
                |
                {' '}
                <Link to="/childrens-karate-burntwood">Burntwood</Link>
                {' '}
                |
                {' '}
                <Link to="/childrens-karate-hednesford">Hednesford</Link>
                {' '}
                |
                {' '}
                <Link to="/sitemap">Sitemap</Link>
              </p>
            </div>
            <div className="divider-20 d-none d-lg-block" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
