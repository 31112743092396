import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MobileView } from 'react-device-detect';
import { useToken } from '../TokenProvider';

function Navigation() {
  const { token } = useToken();
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar variant="dark" expand="sm" expanded={expanded}>
      <Container>
        <Navbar.Toggle aria-controls="navbar-dark" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="navbar-dark">
          <Nav>
            <NavLink as={Link} to="/" onClick={() => setExpanded(false)}>Home</NavLink>
            <NavDropdown
              title="Karate"
              menuVariant="dark"
            >
              <NavDropdown
                title="Training"
                menuVariant="dark"
              >
                <div className="childClasses">
                  <NavDropdown
                    title="Child Classes"
                    menuVariant="dark"
                  >
                    <div className="childClassLocations">
                      <NavDropdown.Item as={Link} to="/childrens-karate-ashby-de-la-zouch" onClick={() => setExpanded(false)}>Ashby de la Zouch</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/childrens-karate-burntwood" onClick={() => setExpanded(false)}>Burntwood</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/childrens-karate-tamworth" onClick={() => setExpanded(false)}>Tamworth</NavDropdown.Item>
                    </div>
                  </NavDropdown>
                </div>
                <NavDropdown.Item as={Link} to="/karate/private-tuition" onClick={() => setExpanded(false)}>Private Tuition</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/karate/grading-system" onClick={() => setExpanded(false)}>Grading System</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Item as={Link} to="/pricing" onClick={() => setExpanded(false)}>Pricing</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/karate/benefits-for-children" onClick={() => setExpanded(false)}>Benefits</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/karate/the-english-karate-organisation" onClick={() => setExpanded(false)}>The EKO</NavDropdown.Item>
            </NavDropdown>
            <NavLink as={Link} to="/about-us" onClick={() => setExpanded(false)}>About Us</NavLink>
            <NavDropdown
              title="Instructors"
              menuVariant="dark"
              style={{ marginRight: 15 }}
            >
              <NavDropdown.Item as={Link} to="/instructors/sensei-mike-hills" onClick={() => setExpanded(false)}>Sensei Mike</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/instructors/sensei-rob-hills" onClick={() => setExpanded(false)}>Sensei Rob</NavDropdown.Item>
            </NavDropdown>
            <NavLink as={Link} to="/contact-us" onClick={() => setExpanded(false)}>Contact Us</NavLink>

            <MobileView>
              <NavLink as={Link} to={token ? '/members/dashboard' : '/members/login'} onClick={() => setExpanded(false)}>
                {token ? 'Members Area' : 'Members Login'}
              </NavLink>
            </MobileView>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
