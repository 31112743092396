import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingHednesford() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Hednesford, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate hednesford, children's self defence hednesford" />
        <meta name="description" content="Enhance your child's discipline and self-defense skills with Hills Combat Children's Karate Hednesford. Classes for ages 4-15, just a short drive away" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Hednesford" />
      <Training
        club="burntwood"
        name="Children&apos;s Karate Hednesford"
        image="/images/gallery/childrens-karate-hednesford.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to Hills Combat
            {' '}
            <strong>Children&apos;s Karate Hednesford</strong>
            , the premier destination for karate for children in the Hednesford area.
            If you&apos;re looking for a fun and challenging activity for your child that will
            help them develop physical fitness, self-confidence, and self-defense skills,
            look no further than our classes at the Chase Terrace Scout Hall in Burntwood.
          </p>

          <p>
            Our experienced instructors specialise in teaching kids aged 4 to 15 the art of karate,
            equipping them with the necessary skills and discipline to excel.
            Although our classes are located just a short 10-minute drive from Hednesford,
            we welcome students from all surrounding areas to join us in learning the traditional
            Japanese martial art of Karate.
          </p>

          <p>
            Our program is suitable for children of all skill levels, whether your child is a
            beginner or has been practicing karate for years. Our instructors tailor their
            instruction to each student&apos;s needs and goals, guiding them through their
            progress at a comfortable pace.
          </p>

          <p>
            Our club is proud to be a member of the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation</Link>
            {' '}
            (EKO).
            As a member, our students have access to a recognised grade authority,
            as well as a wealth of training and competition opportunities that can help
            them take their karate skills to the next level.
            Our instructors are EKO-approved, meaning they meet the highest
            standards of instruction and safety.
          </p>

          <p>
            At Hills Combat Children&apos;s Karate Hednesford, we also focus on developing
            respect, discipline, and conflict resolution skills in our students.
            These skills are essential for children and will help them succeed in all
            areas of their life.
          </p>

          <p>
            If you have any questions about our Karate classes near Hednesford,
            please don&apos;t hesitate to contact
            {' '}
            <Link to="/instructors/sensei-mike-hills" title="Sensei Mike">Mike</Link>
            {' '}
            at
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            .
            He&apos;ll be happy to provide you with any additional information you need.
          </p>

          <p className="excerpt">
            Hills Combat Children&apos;s Karate classes are held every Monday from 5:45 pm
            to 6:45 pm at the 1st Chase Terrace Scouts, 99 Ironstone Rd, Chase Terrace,
            Burntwood WS7 1LL.
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingHednesford;
