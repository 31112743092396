import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone } from '@fortawesome/fontawesome-free-solid';

function ContactInfo() {
  return (
    <section className="s-pb-40 s-pt-0 s-py-lg-50 c-my-40 c-my-lg-15 ds contact-info">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 text-center">
            <div className="icon-box icon-absolute text-center">
              <div className="icon-styled">
                <FontAwesomeIcon icon={faPhone as any} />
              </div>
              <span>CALL US TODAY</span>
              <h5>+447930 339609 | +447860 465577</h5>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center">
            <div className="icon-box icon-absolute text-center">
              <div className="icon-styled">
                <FontAwesomeIcon icon={faMapMarker as any} />
              </div>
              <span>BURNTWOOD</span>
              <h5>MON 5:45PM - 6:45PM</h5>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center">
            <div className="icon-box icon-absolute text-center">
              <div className="icon-styled">
                <FontAwesomeIcon icon={faMapMarker as any} />
              </div>
              <span>TAMWORTH</span>
              <h5>WED 7:00PM - 8:00PM</h5>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center">
            <div className="icon-box icon-absolute text-center">
              <div className="icon-styled">
                <FontAwesomeIcon icon={faMapMarker as any} />
              </div>
              <span>ASHBY-DE-LA-ZOUCH</span>
              <h5>THUR 5:00PM - 7:00PM</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactInfo;
