import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function HeianNidan() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Heian Nidan | Peaceful Mind - Second Level | Hills Combat</title>
      </Helmet>

      <PageHeader name="Heian Nidan" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
        <OnlineTraining
          defaultVideo="/videos/kata/heian-nidan-front-view.mp4"
          title="Heian Nidan"
          subTitle="Peaceful Mind - Second Level"
          videos={[
            {
              src: '/videos/kata/heian-nidan-front-view.mp4',
              image: '/images/members-area/kata/heian-nidan-front-view.jpg',
              title: 'Heian Nidan (front view)',
            },
            {
              src: '/videos/kata/heian-nidan-rear-view.mp4',
              image: '/images/members-area/kata/heian-nidan-rear-view.jpg',
              title: 'Heian Nidan (rear view)',
            },
          ]}
        >
          <>
            <p>
              <strong>1 - Hidari jodan tate-uke / Migi jodan-kamae (Haiwan-uke) :</strong>
              {' '}
              Turn the head to the left and advance the left foot to the left at 90 °
              in a kokutsu-dachi position , raising the arms from bottom to top,
              up to the vertical plane. The arms draw a rectangle, closed fists turned outward.
              The left forearm performs a vertical parry outward (jodan tate-uke),
              while the right forearm performs a horizontal protection of the
              forehead (jodan-kamae).
            </p>

            <p>
              <strong>2 - Migi ura-zuki / Hidari nagashi-uke:</strong>
              {' '}
              On the spot, quickly bring back the left fist to the right shoulder,
              back of the hand to the outside (nagashi-uke).
              At the same time, send the right fist, back down and forward (ura-zuki).
            </p>

            <p>
              <strong>3 - Hidari chudan yoko-zuki:</strong>
              {' '}
              On the spot, strike with a left middle fist on your left (yoko-zuki ),
              quickly bringing your right fist back into hikeness. The bust is in profile.
            </p>

            <p>
              <strong>4 - Migi jodan tate-uke / Hidari jodan-kamae (haiwan-uke):</strong>
              {' '}
              Turn the head to the right, rotate 2 feet to the right 180 degrees in a
              kokutsu-dachi position , raising arms from bottom to top,
              up to the vertical plane. The arms draw a rectangle, closed fists turned outward.
              The right forearm performs a vertical parry outward (jodan tate-uke),
              while the left forearm performs a horizontal protection of the
              forehead (jodan-kamae).
              This rotation is done with transfer of the weight of the right
              leg on the left leg with movement of the hip.
            </p>

            <p>
              <strong>5 - Hidari ura-zuki / Migi nagashi-uke:</strong>
              {' '}
              On the spot, quickly bring the right fist to the left shoulder,
              back of the hand to the outside (nagashi-uke).
              At the same time, send the left fist, back down and front (ura-zuki)
            </p>

            <p>
              <strong>6 - Migi chudan yoko-zuki:</strong>
              {' '}
              On the spot, strike with the right right fist on the right (yoko-zuki ),
              quickly bringing back the left fist in hikeness. The bust is in profile.
            </p>

            <p>
              <strong>
                7 - Migi jodan uraken-yoko-mawashi-uchi /
                Migi sokuto-yoko-geri-keage:
              </strong>
              {' '}
              At first, turn your head to the right, bring your fists to the left flank,
              one on the other, the left in hikite and upright inuprightposition.
              Move your left foot a little under your center of gravity,
              turning your hips to the left.
              Perform a right side whip (Yoko-geri-keage) kick together
              with a high sweep of the right fist (uraken-uchi).
            </p>

            <p>
              <strong>8 - Hidari chudan shuto-uke:</strong>
              {' '}
              Let the right leg come back naturally against the left leg, rotate the left foot 90°
              to the left by turning the head in the same direction.
              Fall back into kokutsu-dachi performing a mid-level saber blocking (shuto-uke)
            </p>

            <p>
              <strong>9 - Migi chudan shuto-uke:</strong>
              Advance the right foot on the same axis in kokutsu-dachi positionand
              block the saber with the right hand level (shuto-uke).
            </p>

            <p>
              <strong>10 - Hidari chudan shuto-uke:</strong>
              {' '}
              Advance the left foot on the same axis in kokutsu-dachi positionand block
              the sword of the left hand level medium (shuto-uke).
            </p>

            <p>
              <strong>11 - Migi chudan nukite-tate-zuki / Hidari osae-uke:</strong>
              {' '}
              Advance the right foot on the same axis, in zenkutsu-dachi with a vertical spike
              (tate-nukite) of the right hand, in a straight line over the hand left
              that you fold in front of you during the passage forward (osae-uke).
            </p>

            <hr />
            <p><strong>KIAÏ !!!</strong></p>
            <hr />

            <p>
              <strong>12 - Hidari chudan shuto-uke:</strong>
              Pivot on the right foot 90 ° to the rear, move the left foot to pass in kokutsu-dachi
              with a saber lock of the left hand average level (shuto-uke)
            </p>

            <p>
              <strong>13 - Migi chudan shuto-uke:</strong>
              {' '}
              Swivel on the left foot 45 ° from the previous axis to the right, and advance the
              right foot with a saber lock with the right hand middle level (shuto-uke)
              in position kokutsu-dachi .
            </p>

            <p>
              <strong>14 - Migi chudan shuto-uke:</strong>
              {' '}
              Swivel on the left foot 45°
              from the previous axis to the right, and advance the right foot with a
              saber lock of the right hand middle level (shuto-uke) in position kokutsu-dachi .
            </p>

            <p>
              <strong>15 - Hidari chudan shuto-uke:</strong>
              Pivot on the right foot of 45 ° with respect to the previous axis to the left,
              and advance the left foot with a blockage of the saber of the left hand middle level
              (shuto-uke) in position kokutsu-dachi .
            </p>

            <p>
              <strong>16 - Migi chudan uchi-ude-uke: </strong>
              Move the left foot in an arc of 45 ° to the left, rotate on the right foot to pass
              in zenkutsu-dachi wide. At the same time, perform a medium forearm defense from the
              inside out to the middle level (uchi-ude-uke) with a strong rotation of the
              hips in the opposite direction.
            </p>

            <p>
              <strong>17 - Migi mae-geri-keage:</strong>
              {' '}
              Perform a right-hand whipped (mae-geri-keage) right level kick without
              the upper body moving.
            </p>

            <p>
              <strong>18 - Hidari chudan gyaku-zuki:</strong>
              {' '}
              Bring back the right leg after the kick, and lay it forward in the zenkutsu-dachi
              position while chaining on the spot with a left-hand mid-level left
              punch (gyaku-zuki).
            </p>

            <p>
              <strong>19 - Hidari chudan uchi-ude-uke:</strong>
              On the spot, turn the hips powerfully to the right without removing the
              weight of the front leg. This action brings the right foot a little back.
              At the same time make a blockage of the left forearm from the inside out
              to the middle level (uchi-ude-uke).
            </p>

            <p>
              <strong>20 - Hidari mae-geri-keage:</strong>
              Perform a left-hand whipped (mae-geri-keage) kick in the middle level
              without the upper body moving.
            </p>

            <p>
              <strong>21 - Migi chudan gyaku-zuki:</strong>
              {' '}
              Bring back the left leg after the kick, and lay it forward in zenkutsu-dachi
              positionwhile chaining on the spot with a right rightmiddle level
              (gyaku-zuki) punch.
            </p>

            <p>
              <strong>22 - Migi chudan morote-uke: </strong>
              Move your right foot on the same axis and perform a medium level (morote-uke)
              blocking, from inside to outside, in zenkutsu-dachi.
              The left arm is stuck to the trunk. The bust is 3/4 of a face.
            </p>

            <p>
              <strong>23 - Hidari gedan-barai:</strong>
              {' '}
              Move the left foot in an arc towards your left rear by swinging on your right
              foot 90 ° in zenkutsu-dachi position. Do at the same time a low block of the left arm
              (gedan-barai), right fist in hikité.
            </p>

            <p>
              <strong>24 - Migi jodan age-uke:</strong>
              {' '}
              On the spot, turn the head at 45 ° to the right and make a block from the bottom to
              the top of the left hand open (shuto-age-uke), right fist in hikité - Quickly chase
              advancing the right foot at 45 ° from the previous axis, in zenkutsu-dachi,
              with a blockage rising from the right arm (jodan-age-uke), right fist in hikité.
            </p>

            <p>
              <strong>25 - Migi gedan-barai: </strong>
              {' '}
              Move the right foot in a circular arc towards the back and turn on your left
              foot of 135° in position zenkutsu-dachi with a low block of the right arm
              (gedan-barai), left fist in hikité.
            </p>

            <p>
              <strong>26 - Hidari jodan age-uke:</strong>
              {' '}
              On the spot, turn the head at 45 ° to the left and make a blocking from the
              bottom to the top of the open right hand (shuto-age-uke), left fist in hikité -
              Quickly chase advancing the left foot at 45 ° from the previous axis, in
              zenkutsu-dachi, with a blockage rising from the left arm (jodan-age-uke),
              right fist in hikité.
            </p>

            <hr />
            <p><strong>KIAÏ !!!</strong></p>
            <hr />
          </>
        </OnlineTraining>
      )}
    </>
  );
}

export default HeianNidan;
