import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export interface IVideo {
  image: string
  title: string
  src?: string
  link?: string
}

interface IProps {
  title: string
  videos: IVideo[]
  loadedVideo: string
  setVideo: (video: string) => void
}

function Video({
  title, image, src, link, loadedSrc, setVideo,
}: IVideo & { setVideo: IProps['setVideo'], loadedSrc: string }) {
  const navigate = useNavigate();

  return (
    <div className="playlist-body">
      <div className="media">
        <div className="media-left">
          { link && (
            <button type="button" onClick={() => navigate(link)} className={window.location.pathname === link ? 'loaded' : ''}>
              <img src={image} alt={title} />
            </button>
          ) }
          { src && (
            <button type="button" onClick={() => setVideo(src)} className={loadedSrc === src ? 'loaded' : ''}>
              <img src={image} alt={title} />
            </button>
          )}
        </div>
        <div className="media-body">
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
}

Video.defaultProps = {
  src: null,
  link: null,
};

function PlayList({
  title, videos, loadedVideo, setVideo,
}: IProps) {
  return (
    <div style={{ marginBottom: '10px' }}>
      <h2 className="card-header">
        <span className="playlist-heading">
          {' '}
          <FontAwesomeIcon icon="video" />
          {title}
        </span>
      </h2>
      {videos.map((video, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Video key={index} {...video} setVideo={setVideo} loadedSrc={loadedVideo} />
      ))}
    </div>
  );
}

export default PlayList;
