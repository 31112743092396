import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import Instructor from '../../components/Instructor';

function MikeHills() {
  return (
    <>
      <Helmet>
        <title>Sensei Rob Hills | Hills Combat</title>
      </Helmet>
      <PageHeader name="Sensei Rob" />
      <Instructor firstName="Rob" lastName="Hills">
        <p className="excerpt text-lg-left">
          Meet Rob, one of the experienced instructors at Hills Combat. In 1990, at age 4,
          Rob started practising Shotokan Karate with his father, Mike. His parents wanted
          him to learn self-defence and to gain confidence to deal with some bullies at
          primary school.
        </p>
        <p className="text-lg-left">
          In September 1996, as a result of hard work and dedication, Rob achieved his 1st
          Dan black belt. However, this was just the start. Rob continued to practice
          3-times a week, eventually gaining his 2nd Dan in December 1999.
          <br />
          <br />
          Rob has won many Karate competitions durting his martial arts career.
          His proudest was 2nd place in Kumite at the English Karate National competition.
          Rob would have come 1st, but was unfortunate to be controversially disqualified
          in the final.
          <br />
          <br />
          Rob and his father set up their first Shotokan Karate club in 2000.
          The club was called Chase Karate Club and had many successful
          students (children and adults).
          <br />
          <br />
          Rob also practices kickboxing and has experience in MMA. Today, Rob is proud
          to be a sensei (instructor) at Hills Combat.
        </p>
      </Instructor>
    </>
  );
}

export default MikeHills;
