import React, { ReactNode } from 'react';

interface IInstructor {
  firstName: string
  lastName: string
  children: ReactNode
}

function Instructor({ firstName, lastName, children }: IInstructor) {
  return (
    <section className={`ds s-py-60 s-py-md-90 s-overlay mobile-overlay about-section ${firstName.toLowerCase()}-${lastName.toLowerCase()}`}>
      <div className="container">
        <div className="row d-flex justify-content-end">
          <div className="divider-70 d-none d-xl-block" />
          <div className="col-md-12 col-xl-8 ">
            <h3 className="special-heading text-lg-left">
              about
              {' '}
              <span
                className="color-main"
              >
                {firstName}
              </span>
              {' '}
              {lastName}
            </h3>
            <div className="divider-40 d-none d-xl-block" />
            { children }

            <div className="signature">
              <div className="signature__left">
                <h5>
                  {firstName}
                  {' '}
                  {lastName}
                </h5>
                <span>CO-FOUNDER / INSTRUCTOR</span>
              </div>
            </div>
          </div>
          <div className="divider-60 d-none d-xl-block" />
        </div>
      </div>
    </section>
  );
}

export default Instructor;
