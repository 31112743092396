import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Training from '../../components/Training';

function GradingSystem() {
  return (
    <>
      <Helmet>
        <title>The Karate Grading System | Hills Combat</title>
      </Helmet>
      <PageHeader name="The Karate Grading System" />
      <Training name="The Karate Grading System" image="/images/gallery/karate-belt-red.jpg">
        <>
          <p className="excerpt">
            At Hills Combat, it&apos;s not mandatory to participate in a grading. However,
            most students believe passing a grade is a great way to showcase their progress.
            The instructor will only ask the student once they are ready and should
            they wish to participate in gradings.
          </p>

          <h4>What is a grading?</h4>
          <p>
            A Karate grading is like a test. Once the instructor feels a student has
            reached a certain standard with their Karate techniques and Kata,
            they&apos;ll put them forward for their grading. Once the student has
            successfully passed, they&apos;ll move on to their next grade to learn
            more advanced skills.
          </p>

          <h4>What is involved?</h4>
          <p>
            Once a student has attended a sufficient amount of training and gained
            the skills required for a Kyu grade (belt colour), the instructor will
            ask the student if they would like to take their grading.
            At Hills Combat, we will hold a grading every three months.
            The student will be examined on the techniques for their grade only.
            If the student successfully passes their grading, they will
            be awarded their belt and certificate on the day.
          </p>

          <h4>How long does it take to get a black belt?</h4>
          <p>
            It all depends on the student and their commitment to Karate. However,
            a typical timescale is around five years.
          </p>

          <h4>Kyu grade belt colours</h4>

          <ul className="list-styled">
            <li>10th Kyu - White</li>
            <li>9th Kyu - Red</li>
            <li>8th Kyu - Orange</li>
            <li>7th Kyu - Yellow</li>
            <li>6th Kyu - Green</li>
            <li>5th Kyu - Purple</li>
            <li>4th Kyu - Purple with white stripe</li>
            <li>3rd Kyu - Brown</li>
            <li>2nd Kyu - Brown with white stripe</li>
            <li>1st Kyu - Brown with red stripe</li>
            <li>Shodan Black belt</li>
          </ul>

          <p>
            You can train and take your grades at either Hills Combat
            {' '}
            <Link to="/childrens-karate-burntwood">Children&apos;s Karate Burntwood</Link>
            {' '}
            or Hills Combat
            {' '}
            <Link to="/childrens-karate-ashby-de-la-zouch">Children&apos;s Karate Ashby de-la-Zouch</Link>
            .
          </p>
        </>
      </Training>
    </>
  );
}

export default GradingSystem;
