import React from 'react';
import { Link } from 'react-router-dom';

function PageHeader({ name }: { name: string }) {
  return (
    <section className="page_title ds s-parallax s-overlay s-py-45">
      <div className="container">
        <div className="row">
          <div className="divider-45">{}</div>
          <div className="col-md-12 text-center">
            <h1 className="bold">{name}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" title="Hills Combat Self Defence Karate">Home</Link>
              </li>
              <li className="breadcrumb-item">
                Pages
              </li>
              <li className="breadcrumb-item active">
                {name}
              </li>
            </ol>
          </div>
          <div className="divider-50">{}</div>
        </div>
      </div>
    </section>
  );
}

export default PageHeader;
