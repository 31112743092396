import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';

import PlayList, { IVideo } from './PlayList';
import Contact from '../Contact';
import Accordion from '../Accordion';

interface IProps {
  title: string
  subTitle?: string
  playlistTitle?: string
  videos?: IVideo[]
  defaultVideo: string
  children: ReactNode
}

function OnlineTraining({
  title, subTitle, playlistTitle, children, videos, defaultVideo,
}: IProps) {
  const videoRef = useRef<any>();
  const [video, setVideo] = useState(defaultVideo);

  useEffect(() => {
    videoRef.current?.load();
    videoRef.current?.play();
    window.scrollTo(0, 0);
  }, [video]);

  return (
    <section className="ds s-pt-60 s-pb-0 s-py-md-90 c-gutter-60 c-mb-40">
      <div className="container">
        <div className="row">
          <div className="d-none d-lg-block divider-70" />
          <div className="col-md-7">
            <article
              className="vertical-item content-padding post type-event status-publish format-standard has-post-thumbnail"
            >
              <div className="item-media post-thumbnail">
                <video
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  preload="none"
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  controls
                >
                  <source src={video} type="video/mp4" />
                  <track
                    default
                    srcLang="en"
                    kind="captions"
                    src={video}
                  />
                  Your browser does not support the video.
                </video>
              </div>
              <div className="item-content">
                <div className="entry-content">
                  <h2>{ title }</h2>
                  { subTitle && <h5>{subTitle}</h5>}
                  <hr />
                  { children }
                </div>
              </div>
            </article>
          </div>

          <div className="col-md-5">
            { videos && (
              <PlayList
                setVideo={setVideo}
                title={playlistTitle || `${title} Playlist`}
                videos={videos}
                loadedVideo={video}
              />
            ) }

            <Accordion />
            <Contact />
          </div>
        </div>
      </div>
    </section>
  );
}

OnlineTraining.defaultProps = {
  subTitle: null,
  playlistTitle: null,
  videos: null,
};

export default OnlineTraining;
