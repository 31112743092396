import React from 'react';

function Unauthorised() {
  return (
    <section className="ds s-pt-60 s-pb-0 s-py-md-90 c-gutter-60 c-mb-40">
      <div className="container">
        <div className="row">
          <article
            className="vertical-item content-padding post type-event status-publish format-standard has-post-thumbnail"
          >
            <div className="item-content">
              <div className="entry-content">
                <h2>Unauthorised</h2>
                <hr />
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
}

export default Unauthorised;
