import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../TokenProvider';

function useLoginVerification() {
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const [loggedIn, setLoggedIn] = useState(false);
  const [validating, setValidating] = useState(true);

  const isLoggedIn = async (): Promise<boolean> => {
    const formData = new FormData();
    formData.append('token', token);

    try {
      const { data } = await axios({
        method: 'post',
        url: '/login-verification.php',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoggedIn(data.logged_in);

      return data.logged_in;
    } catch (err) {
      setLoggedIn(false);
      return false;
    }
  };

  const redirectIfNotLoggedIn = async () => {
    const loginUri = '/members/login';

    setValidating(true);

    if (!(await isLoggedIn().catch(() => navigate(loginUri)))) {
      setToken('');
      navigate(loginUri);
    }

    setValidating(false);
  };

  return {
    loggedIn,
    validating,
    redirectIfNotLoggedIn,
  };
}

export default useLoginVerification;
