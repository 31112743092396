import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingMeasham() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Measham, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate measham, children's self defence measham" />
        <meta name="description" content="Hills Combat Children's Karate Measham offers karate for ages 4-15 near Measham. Join us for fun self-defense classes today!" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Measham" />
      <Training
        club="ashby"
        name="Children&apos;s Karate Measham"
        image="/images/gallery/childrens-karate-measham.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to Hills Combat
            {' '}
            <strong>Children&apos;s Karate Measham</strong>
            ,
            the leading provider of karate classes for children in and around Measham.
            Our experienced instructors specialise in teaching kids aged 4 to 15 the art of karate,
            equipping them with the skills and discipline needed to excel.
            Classes are held just a short drive away in Packington, but our focus is also on
            serving the Measham community.
          </p>

          <p>
            Our dojo provides classes on Thursdays, with the beginners&apos; session running from 5:00 pm to 6:00 pm and
            the advanced class following from 6:00 pm to 7:00 pm. You can find us at Packington Memorial Hall on High Street,
            Packington, Ashby-de-la-Zouch, LE65 1WJ. These engaging one-hour sessions are tailored to promote physical fitness, self-confidence,
            and self-defence skills in children, ensuring a challenging and enjoyable experience.
          </p>

          <p>
            Our program is suitable for children of all skill levels, whether your child is a
            beginner or has been practising karate for years. Our instructors provide individualised
            attention to each student, tailoring their instruction to help them progress at a pace
            that is comfortable for them.
            We specialise in teaching children the traditional Japanese martial art of
            Karate in a fun, safe, and nurturing environment. Our experienced instructors
            are dedicated to helping each child reach their full potential, both
            physically and mentally.
          </p>

          <p>
            We are proud members of the
            {' '}
            <Link to="/karate/the-english-karate-organisation">EKO</Link>
            {' '}
            (English Karate Organisation) which ensures that
            our instruction and training methods are of the highest quality and that we follow
            strict safety guidelines. The EKO also provides additional training and
            competition opportunities for our students.
          </p>

          <p>
            If you&apos;re looking for a fun and challenging activity for your child in
            the Measham area, look no further than Hills Combat Karate.
            Our classes are the perfect way for kids to stay active, learn valuable
            life skills, and make new friends. Sign up today and experience
            the benefits of children&apos;s martial arts for yourself.
          </p>

          <p>
            If you have any inquiries regarding our Karate classes in the Measham area,
            please reach out to Rob at
            {' '}
            <a href="tel:+447930339609" title="Call Rob">07930 339609</a>
            {' '}
            or Mike at
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            .
            We would be glad to provide you with any additional information you may require.
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingMeasham;
