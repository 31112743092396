import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingSwadlincote() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Swadlincote, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate swadlincote, children's self defence swadlincote" />
        <meta name="description" content="Learn karate and self-defense with Hills Combat Children's Karate Swadlincote. Classes for ages 4-15 near Swadlincote, join a fun and challenging class today!" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Swadlincote" />
      <Training
        club="ashby"
        name="Children&apos;s Karate Swadlincote"
        image="/images/gallery/childrens-karate-swadlincote.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to Hills Combat
            {' '}
            <strong>Children&apos;s Karate Swadlincote</strong>
            , where your child can receive top-notch martial arts training near the Swadlincote area.
            Our Packington Memorial Hall location is just a short 15-minute drive from Swadlincote,
            making it the perfect location for families looking for a fun and challenging activity
            for their children.
          </p>

          <p>
            Our experienced instructors specialise in teaching kids aged 4 to 15 the art of karate,
            providing them with the skills and discipline they need to succeed.
            The class is divided into two groups based on age, with a younger group session (ages 4-7) designed
            to be an introduction to karate and self defense, and an older group session (ages 8-15) that focuses
            on advanced techniques and skills.
          </p>

          <p>
            All students at Hills Combat will learn the fundamentals of self defense, karate and how to stay safe.
            Our classes also focus on developing respect, discipline, conflict resolution and anti-bullying skills
            that are essential for this age group.
          </p>

          <p>
            Karate provides a host of benefits for children, not just in terms of physical fitness but also in
            terms of developing self-confidence, self-discipline, and focus. It&apos;s also a great way to build
            social skills and make new friends.
          </p>

          <p>
            Hills Combat is proud to be a member of the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation</Link>
            {' '}
            (EKO). As a member, our students
            have access to a recognised grade authority, as well as a wealth of training and competition
            opportunities that can help them take their karate skills to the next level. Our instructors are EKO-approved,
            meaning they meet the highest standards of instruction and safety.
          </p>

          <p>
            If you have any questions about our Karate classes near Swadlincote, please don&apos;t hesitate to contact
            Rob at
            {' '}
            <a href="tel:+447930339609" title="Call Rob">07930 339609</a>
            {' '}
            or Mike at
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            {' '}
            We&apos;ll be happy to provide you with any additional information you need.
          </p>

          <p className="excerpt">
            Hills Combat Children&apos;s Karate classes are conducted every Thursday, with the beginners&apos; session scheduled from 5:00 pm to 6:00 pm,
            followed by the advanced class from 6:00 pm to 7:00 pm at the Packington Memorial Hall in Ashby de-la-Zouch, LE65 1WJ.
            We invite you to come and try out a class for free, and see for yourself the positive impact martial arts training can have on your child&apos;s development.
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingSwadlincote;
