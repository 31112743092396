import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import { useToken } from '../TokenProvider';

import logo from '../website-logo.svg';

function Header() {
  const { token } = useToken();

  return (
    <div className="header_absolute" style={{ height: '140px' }}>
      <header className="page_header ds ms justify-nav-center s-pt-10 s-pb-10 affix">
        <div className="container header-container">
          <Link to="/" className="logo">
            <img src={logo} alt="Hills Combat Self Defence Karate" />
          </Link>

          <Navigation />

          <div className="top-includes">
            <Link to={token ? '/members/dashboard' : '/members/login'} className="btn btn-outline-maincolor">
              {token ? 'Members Area' : 'Members Login'}
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
