import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function ShutoUke() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Shuto Uke i Kokutsu Dachi | Hills Combat</title>
      </Helmet>

      <PageHeader name="Shuto Uke i Kokutsu Dachi" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
        <OnlineTraining
          defaultVideo="/videos/technique/shuto-uke.mp4"
          title="Shuto Uke i Kokutsu Dachi"
          subTitle="Open hand block in back stance"
          playlistTitle="Techniques"
          videos={[
            {
              image: '/images/members-area/technique/gyakuzuki.jpg',
              title: 'Gyakuzuki (reverse punch)',
              link: '/techniques/gyakuzuki',
            },
            {
              image: '/images/members-area/technique/mawashi-geri.jpg',
              title: 'Mawashi Geri (roundhouse kick)',
              link: '/techniques/mawashi-geri',
            },
            {
              image: '/images/members-area/technique/shuto-uke.jpg',
              title: 'Shuto Uke i Kokutsu Dachi (open hand block in back stance)',
              link: '/techniques/shuto-uke',
            },
          ]}
        >
          <>
            <p>
              For the 8th Kyu grading, you will learn Shuto Uke i Kokutsu Dachi,
              Japanese for open hand block in back stance. This technique is also a part
              of the
              {' '}
              <Link to="/kata/heian-shodan">Heian Shodan</Link>
              {' '}
              kata.
            </p>

            <hr />
            <h6>Shuto Uke (open hand block)</h6>
            <hr />

            <p>Starting with the left arm out blocking shuto uke.</p>

            <p>
              <strong>1.</strong>
              {' '}
              Lift the right arm up from the stomach to the left shoulder.
              Keep the forearm close to the body as you lift the arm and keep the elbow
              in the center of the chest. When the right hand reaches the left shoulder
              (palm facing the neck), the hand is open, fingers and wrist straight.
            </p>

            <p>
              <strong>2.</strong>
              {' '}
              At the same time you lift the right arm, stretch the left arm forward,
              hand open, palm facing down, fingers and wrist straight.
            </p>

            <p>
              <strong>3.</strong>
              {' '}
              Try and keep the elbow of the right blocking arm in the centre of
              the body and close to the chest.
            </p>

            <p>
              <strong>4.</strong>
              {' '}
              Now start to execute the block with the right arm.
              The blocking arm starts to move forward with the palm of the blocking
              arm facing towards you.
            </p>

            <p>
              <strong>5.</strong>
              {' '}
              As the blocking arm starts to move forward, the left hikite
              arm starts to move back with the palm facing down.
            </p>

            <p>
              <strong>6.</strong>
              {' '}
              As the blocking arm finishes it’s movement, rotate the forearm and
              hand strongly and at exactly the same time, the left hikite arm
              finishes with another strong forearm and hand rotation,
              finishing with the palm facing upwards.
            </p>

            <p>
              So now we have the arm positions for shuto uke.
              Keep practicing the finished arm positions for shuto uke.
              Practice this on both sides.
            </p>

            <hr />
            <h6>Kokutsu Dachi (back stance)</h6>
            <hr />

            <p>
              <strong>1.</strong>
              {' '}
              The key element of this stance is the fact that your centre of gravity should be set
              quite far back. Your rear leg supports approximately two-thirds (66%) of your weight,
              whilst the front leg supports the remainder.
            </p>

            <p>
              <strong>2.</strong>
              {' '}
              The back leg should be quite bent at the knee and the back foot should face
              outwards at a right-angle to the front.
            </p>

            <p>
              <strong>3.</strong>
              {' '}
              The front leg should be slightly bent and the front foot should face directly to the
              front. It should be two shoulder-widths forward of the rear foot, and a line
              drawn from the back of the rear heel would touch the inside of the front foot.
            </p>
            <p>
              <strong>4.</strong>
              {' '}
              Your back should be naturally straight and your body-line should be slightly
              forwards of your rear leg.
            </p>
            <p>
              <strong>5.</strong>
              {' '}
              Many stances require you to turn your hips as you strike, block or kick, but Kokutsu
              Dachi is unusual because it&apos;s one of few where the hips should be set at
              45 degrees to the front in its relaxed position.
            </p>
          </>
        </OnlineTraining>
      )}
    </>
  );
}

export default ShutoUke;
