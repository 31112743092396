import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';
import Accordion from '../components/Training/Accordion';
import Contact from '../components/Training/Contact';

function EKO() {
  return (
    <>
      <Helmet>
        <title>English Karate Organisation | Hills Combat</title>
      </Helmet>
      <PageHeader name="English Karate Organisation" />
      <section className="ds s-pt-60 s-pb-0 s-py-md-90 c-gutter-60 c-mb-40">
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block divider-70" />
            <div className="col-md-7">
              <article
                className="vertical-item content-padding post type-event status-publish format-standard has-post-thumbnail"
              >
                <div className="item-media post-thumbnail">
                  <img src="/images/gallery/english-karate-organisation.jpg" alt="English Karate Organisation" />
                </div>
                <div className="item-content">
                  <div className="entry-content">
                    <h2>Member of the EKO</h2>
                    <hr />
                    <p className="excerpt">
                      Hills Combat is not just any karate club, it is a proud member of the
                      English Karate Organisation (EKO) - a leading association for martial artists.
                    </p>

                    <p>
                      By becoming a student at Hills Combat, you will be joining one of Europe&apos;s most respected karate associations,
                      and automatically gain access to a wide range of benefits. Being a member of the EKO means that our club,
                      instructors, and students have official recognition and status within the karate community.
                      We also have the backing of a governing body, which ensures that our teaching and grading methods
                      are in line with the highest standards. Additionally, the EKO provides liability insurance and a
                      child protection policy for all members, which ensures that our students are safe and protected while training.
                    </p>
                    <br />
                    <p>
                      Another great benefit of being a member of the EKO is the opportunity to participate in their
                      annual karate competition, which takes place in Birmingham. Our students have the chance to
                      showcase their skills and compete against other martial artists from across the country.
                      {' '}
                      <Link to="/instructors/sensei-rob-hills">Sensei Rob</Link>
                      , one of our instructors, has a proud record of winning many trophies at
                      EKO competitions in both Kata and Kumite.
                      {' '}
                      <Link to="/instructors/sensei-mike-hills">Sensei Mike</Link>
                      , also one of our instructors,
                      is a qualified referee with the EKO and has helped in many events.
                    </p>
                    <br />
                    <p>
                      In short, being a member of the EKO is important for Hills Combat as it ensures that our club,
                      instructors, and students are held to the highest standards and have access to benefits
                      that support the development of our students. It also allows our students to connect with other
                      martial artists and showcase their skills in a competitive setting.
                    </p>

                    <hr />
                    <p>
                      Below are some of the benefits of being a member:
                    </p>
                    <hr />

                    <ul className="list-styled">
                      <li>Official recognition for clubs, instructors & students</li>
                      <li>Recognised karate grade authority</li>
                      <li>Governing body recognition</li>
                      <li>Liability insurance</li>
                      <li>Child protection policy</li>
                    </ul>
                  </div>

                </div>
              </article>
            </div>

            <div className="col-md-5">
              <Accordion />
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EKO;
