import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone } from '@fortawesome/fontawesome-free-solid';
import useContact, { EMAIL_REGEX } from '../../hooks/useContact';

function Contact({ club }: { club?: 'ashby' | 'burntwood' | 'tamworth' }) {
  const {
    register, submitForm, errors, unknownError, formSuccessful,
  } = useContact();

  return (
    <>
      <div className="my-30">
        { club === 'ashby' && (
          <p className="icon-inline">
            <span className="icon-styled color-main">
              <FontAwesomeIcon icon={faMapMarker as any} />
            </span>
            <span>Packington Memorial Hall, Ashby de-la-Zouch, LE65 1WJ</span>
          </p>
        ) }

        { club === 'burntwood' && (
          <p className="icon-inline">
            <span className="icon-styled color-main">
              <FontAwesomeIcon icon={faMapMarker as any} />
            </span>
            <span>1st Chase Terrace Scouts, 99 Ironstone Rd, Burntwood WS7 1LL</span>
          </p>
        ) }

        { club === 'tamworth' && (
          <p className="icon-inline">
            <span className="icon-styled color-main">
              <FontAwesomeIcon icon={faMapMarker as any} />
            </span>
            <span>Newton Regis Village Hall, Austrey Lane, Tamworth, B79 0NL</span>
          </p>
        ) }

        { !club && (
          <>
            <p className="icon-inline">
              <span className="icon-styled color-main">
                <FontAwesomeIcon icon={faMapMarker as any} />
              </span>
              <span>Packington Memorial Hall, Ashby de-la-Zouch, LE65 1WJ</span>
            </p>

            <p className="icon-inline">
              <span className="icon-styled color-main">
                <FontAwesomeIcon icon={faMapMarker as any} />
              </span>
              <span>1st Chase Terrace Scouts, 99 Ironstone Rd, Burntwood WS7 1LL</span>
            </p>
          </>
        ) }

        <p className="icon-inline">
          <span className="icon-styled color-main">
            <FontAwesomeIcon icon={faPhone as any} />
          </span>
          <span>+447930 339609 or +447860 465577</span>
        </p>
      </div>

      <form className="ds ms my-30 p-30" method="post" onSubmit={submitForm}>
        {formSuccessful && (
        <div className="alert alert-success">
          <p>Thank you for contacting us</p>
        </div>
        )}

        {unknownError && (
          <div className="alert alert-danger">
            <p>Oops, there was an error</p>
          </div>
        )}

        <div className="form-group has-placeholder">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            defaultValue=""
            {...register('name', { required: true })}
          />

          {errors.name && <p className="text-danger">Please enter your name</p>}
        </div>

        <div className="form-group has-placeholder">
          <input
            type="text"
            className="form-control"
            placeholder="Phone number"
            defaultValue=""
            {...register('phone')}
          />
        </div>

        <div className="form-group has-placeholder">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            defaultValue=""
            {...register('email', { required: true, pattern: EMAIL_REGEX })}
          />

          {errors.email && <p className="text-danger">Please enter a valid email</p>}
        </div>

        <div className="form-group has-placeholder">
          <textarea
            id="message"
            className="form-control"
            placeholder="Message"
            defaultValue=""
            {...register('message', { required: true })}
          />

          {errors.message && <p className="text-danger">Please enter the message</p>}
        </div>

        <div className="form-group has-placeholder">
          <div className="mtcaptcha" />
        </div>

        <button type="submit" id="contact_form_submit" name="contact_submit" className="btn btn-maincolor">
          Book Your Free Trial
        </button>
      </form>
    </>
  );
}

Contact.defaultProps = {
  club: null,
};

export default Contact;
