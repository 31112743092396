import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page Not Found | Hills Combat</title>
      </Helmet>
      <section className="ds s-py-60 s-py-md-90 error-404 not-found page_404">
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block divider-110" />
            <div className="col-sm-12 text-center">
              <header className="page-header">
                <h2>
                  404
                  <span className="oops grey">Ooops!</span>
                </h2>
                <h3>
                  Sorry Page Not Found!
                </h3>
              </header>
              <div className="page-content">
                <p>
                  <Link to="/" className="btn btn-maincolor">BACK TO HOMEPAGE</Link>
                </p>
              </div>
            </div>
            <div className="d-none d-lg-block divider-110" />
          </div>
        </div>
      </section>
    </>

  );
}

export default NotFound;
