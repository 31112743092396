import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function MawashiGeri() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Mawashi Geri | Roundhouse Kick | Hills Combat</title>
      </Helmet>

      <PageHeader name="Mawashi Geri" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
        <OnlineTraining
          defaultVideo="/videos/technique/mawashi-geri.mp4"
          title="Mawashi Geri"
          subTitle="Roundhouse Kick"
          playlistTitle="Techniques"
          videos={[
            {
              image: '/images/members-area/technique/gyakuzuki.jpg',
              title: 'Gyakuzuki (reverse punch)',
              link: '/techniques/gyakuzuki',
            },
            {
              image: '/images/members-area/technique/mawashi-geri.jpg',
              title: 'Mawashi Geri (roundhouse kick)',
              link: '/techniques/mawashi-geri',
            },
            {
              image: '/images/members-area/technique/shuto-uke.jpg',
              title: 'Shuto Uke i Kokutsu Dachi (open hand block in back stance)',
              link: '/techniques/shuto-uke',
            },
          ]}
        >
          <>
            <p>
              For the 8th Kyu grading, you will learn the Mawashi Geri technique,
              Japanese for a roundhouse kick.
            </p>

            <p>
              The roundhouse kick is what it says on the tin as the leg comes around from the side.
              The best way to do this kick is to swing your rear knee towards the opponent and pivot
              on your standing foot. This is the most crucial part of this technique because
              if you don&lsquo;t, you&lsquo;ll find it very hard to do the kick.
              Once the kicking leg is up, extend it shortly before the kick lands.
              The back leg, belly button, and rear shoulder should be parallel at
              the point of impact.
            </p>

            <p>
              <strong>Important:</strong>
              {' '}
              Make sure you twist the leg and foot when pivoting on the standing leg to
              avoid a knee injury.
            </p>
          </>
        </OnlineTraining>
      )}
    </>
  );
}

export default MawashiGeri;
