import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    mtcaptcha: Record<string, any>
    mtcaptchaConfig: Record<string, any>
    mtVerified: (state: Record<string, any>) => void
  }
}

export const verifyUrl = '/mail.php';

const mtConfig = {
  sitekey: 'MTPublic-6qqbMIiOw',
  widgetSize: 'mini',
  // 'verified-callback': 'mtVerified',
  customLangText: {
    en: {
      inputPrompt: 'Are you a bot?',
    },
  },
  customStyle: {
    invalidMsgTextColor: '#e43d4e',
    inputBorderColor: {
      hover: '#13182a',
    },
    buttonIconColor: {
      verify: '#13182a',
      fail: '#e43d4e',
      audiofocus: '#13182a',
    },
  },
};

function reset() {
  const { mtcaptcha } = window;

  if (mtcaptcha) {
    mtcaptcha.renderUI();
    mtcaptcha.resetUI();
  }
}

function MtCaptcha() {
  const { pathname } = useLocation();

  useEffect(reset, []);
  useEffect(reset, [pathname]);

  return (
    <Helmet>
      <script type="text/javascript">
        {`window.mtcaptchaConfig = ${JSON.stringify(mtConfig)};`}
      </script>
      <script type="text/javascript" async src="https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js" />
      <script type="text/javascript" async src="https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js" />
    </Helmet>
  );
}

export default MtCaptcha;
