import { useState } from 'react';
import axios from 'axios';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../TokenProvider';

type Inputs = {
  username: string,
  password: string,
};

function useLogin() {
  const navigate = useNavigate();
  const { setToken } = useToken();
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const [unknownError, setUnknownError] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async ({
    username, password,
  }) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('mtcaptcha-verifiedtoken', window.mtcaptcha.getVerifiedToken());

    try {
      const { data } = await axios({
        method: 'post',
        url: '/login.php',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (data.token) {
        setToken(data.token);
        navigate('/members/dashboard');
        return;
      }

      setUnknownError(true);
      window.mtcaptcha.resetUI();
      setToken('');
    } catch (error) {
      setUnknownError(true);
      window.mtcaptcha.resetUI();
      setToken('');
    }
  };

  return {
    errors,
    register,
    unknownError,
    submitForm: handleSubmit(onSubmit),
  };
}

export default useLogin;
