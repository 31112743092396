import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../components/PageHeader';

function About() {
  return (
    <>
      <Helmet>
        <title>About Us | Hills Combat</title>
      </Helmet>
      <PageHeader name="About Us" />
      <section className="ds s-py-60 s-py-md-90 s-overlay mobile-overlay about-section">
        <div className="container">
          <div className="row d-flex justify-content-end">
            <div className="divider-70 d-none d-xl-block" />
            <div className="col-md-12 col-xl-6 ">
              <h3 className="special-heading text-lg-left">
                about
                {' '}
                <span
                  className="color-main"
                >
                  hills
                </span>
                {' '}
                combat
              </h3>
              <div className="divider-40 d-none d-xl-block" />
              <p className="excerpt text-lg-left">
                Hills Combat started in October 2022 giving children from ages 5 to 15
                the opportunity to learn self-defence in a controlled yet fun way.
              </p>
              <p className="text-lg-left">
                The clubs aim to provide the children with various skills which will hopefully
                benefit them throughout their lives. Firstly, it isn’t about fighting.
                We always endeavour to teach our students to walk away from a fight wherever
                possible. This is about discipline, controlling frustrations and anger and
                learning not to instigate fighting. Self-defence is a last resort if nothing
                else has worked. This is important to know.
                <br />
                <br />
                The benefits of this amazing sport are far-reaching and can help the children
                to get active (we know that kids today spend far too much time on computer games).
                Getting active can help with reducing tension and stress by releasing endorphins
                in the brain leading to a calmer and happier child. Obviously, as a result of
                getting active, doing karate also helps with improving and getting fit over
                time which is good for our kids physical and mental health which is imperative.
                <br />
                <br />
                Hills Combat ensures that all children are made to feel valued as part of
                the team. We at Hills Combat ensure that all of the students are encouraged
                positively to listen to the Sensei (Instructor) to receive and carry out
                instructions to learn this incredibly rewarding sport.
              </p>

              <div className="signature">
                <div className="signature__left">
                  <h5>Mike & Rob Hills</h5>
                  <span>CO-FOUNDERS / INSTRUCTORS</span>
                </div>
              </div>
            </div>
            <div className="divider-60 d-none d-xl-block" />
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
