import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Training from '../../../components/Training';

function KidsTrainingMoira() {
  return (
    <>
      <Helmet>
        <title>
          Children&apos;s Karate Moira, Children&apos;s Self Defence
        </title>
        <meta name="keywords" content="children's karate moira, children's self defence moira" />
        <meta name="description" content="Learn karate and self-defense with Hills Combat Children's Karate Moira. Classes for ages 4-15 near Moira, join a fun and challenging class today!" />
      </Helmet>
      <PageHeader name="Children&apos;s Karate Moira" />
      <Training
        club="ashby"
        name="Children&apos;s Karate Moira"
        image="/images/gallery/childrens-karate-moira.jpg"
      >
        <>
          <p className="excerpt">
            Welcome to Hills Combat
            {' '}
            <strong>Children&apos;s Karate Moira</strong>
            , the top choice for karate for children in the Moira area.
            Our experienced instructors specialise in teaching kids aged 4 to 15 the art of karate,
            providing them with the skills and discipline they need to succeed.
            Our classes are located just a short 9-minute drive from Moira, making
            it convenient and easy for families in the area to attend.
          </p>

          <p>
            In our dojo, we host Thursday evening classes at Packington Memorial Hall, starting with the beginners&apos; class at 5:00 pm and followed by the advanced class from 6:00 pm to 7:00 pm.
            Each class lasts for one hour and combines challenging and fun elements, helping
            children to improve their physical fitness, self-confidence, and
            self-defense abilities.
          </p>

          <p>
            Our program is suitable for children of all skill levels, whether they are just
            starting out or have been practicing karate for a while. Our instructors take the
            time to understand each student&apos;s individual needs and goals, allowing them to
            progress at their own pace. We focus on teaching the traditional Japanese
            martial art of Karate in a fun, safe, and supportive environment. Our passionate
            instructors are committed to helping each child reach their full potential in
            both physical and mental aspects.
          </p>

          <p>
            We are honored to be a member of the
            {' '}
            <Link to="/karate/the-english-karate-organisation">English Karate Organisation</Link>
            {' '}
            (EKO) ensuring that our
            instruction and training techniques are of the highest quality and that we
            abide by strict safety standards. The EKO also provides additional training and
            competition opportunities for our students.
          </p>

          <p>
            If you are searching for an enjoyable and challenging activity for your child in
            the Moira area, look no further than Hills Combat Karate. Our classes are the
            ideal way for children to stay active, learn valuable life skills, and make new friends.
            Sign up today and experience the benefits of children&apos;s martial arts for yourself.
          </p>

          <p>
            If you have any questions about our Karate classes in the Moira area, please do not
            hesitate to contact Rob at
            {' '}
            <a href="tel:+447930339609" title="Call Rob">07930 339609</a>
            {' '}
            or Mike at
            {' '}
            <a href="tel:+447860465577" title="Call Mike">07860 465577</a>
            . We would be happy to
            provide you with any additional information you may need.
          </p>

          <p className="excerpt">
            Hills Combat Children&apos;s Karate takes place on Thursdays at Packington Memorial Hall, High Street, Packington, Ashby-de-la-Zouch, LE65 1WJ.
            The beginners&apos; class begins at 5:00 pm, and the advanced class follows from 6:00 pm to 7:00 pm.
          </p>
        </>
      </Training>
    </>
  );
}

export default KidsTrainingMoira;
