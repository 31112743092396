import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const root = document.getElementById('root');

function Application() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

if (root?.hasChildNodes()) {
  hydrate(<Application />, root);
} else {
  render(<Application />, root);
}
