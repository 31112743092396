import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

function Sitemap() {
  return (
    <>
      <Helmet>
        <title>Sitemap | Hills Combat</title>
      </Helmet>
      <PageHeader name="Sitemap" />
      <section className="ds s-py-60 s-py-md-90 s-overlay mobile-overlay about-section">
        <div className="container">
          <div className="row d-flex justify-content-end">
            <div className="divider-70 d-none d-xl-block" />
            <div className="col-md-12 col-xl-6 ">
              <h3 className="special-heading text-lg-left">
                Sitemap
              </h3>
              <div className="divider-40 d-none d-xl-block" />
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-ashby-de-la-zouch">Children&apos;s Karate Ashby de-la-Zouch</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-tamworth">Children&apos;s Karate Tamworth</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-burntwood">Children&apos;s Karate Burntwood</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-measham">Children&apos;s Karate Measham</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-moira">Children&apos;s Karate Moira</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-swadlincote">Children&apos;s Karate Swadlincote</Link>
                </li>
                <li>
                  <Link to="/childrens-karate-hednesford">Children&apos;s Karate Hednesford</Link>
                </li>
                <li>
                  <Link to="/karate/private-tuition">Private Karate Tuition</Link>
                </li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/karate/benefits-for-children">Benefits of Martial Arts for Children</Link>
                </li>
                <li>
                  <Link to="/karate/the-english-karate-organisation">The EKO</Link>
                </li>
                <li>
                  <Link to="/karate/grading-system">Karate Grading System</Link>
                </li>
                <li>
                  <Link to="/instructors/sensei-mike-hills">Sensei Mike Hills</Link>
                </li>
                <li>
                  <Link to="/instructors/sensei-rob-hills">Sensei Rob Hills</Link>
                </li>
              </ul>
            </div>
            <div className="divider-60 d-none d-xl-block" />
            <div className="col-md-12 col-xl-6 " style={{ marginTop: '50px' }}>
              <h3 className="special-heading text-lg-left">
                Members Area
              </h3>
              <div className="divider-40 d-none d-xl-block" />
              <ul>
                <li>
                  <Link to="/members/login">Members Login</Link>
                </li>
                <li>
                  <Link to="/members/dashboard">Members Dashboard</Link>
                </li>
                <li>
                  <Link to="/kata/heian-shodan">Heian Shodan</Link>
                </li>
                <li>
                  <Link to="/kata/heian-nidan">Heian Nidan</Link>
                </li>
                <li>
                  <Link to="/kata/heian-sandan">Heian Sandan</Link>
                </li>
                <li>
                  <Link to="/techniques/mawashi-geri">Mawashi Geri</Link>
                </li>
                <li>
                  <Link to="/techniques/shuto-uke">Shuto Uke i Kokutsu Dachi</Link>
                </li>
                <li>
                  <Link to="/techniques/gyakuzuki">Gyakuzuki</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sitemap;
