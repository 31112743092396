import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ISlide {
  image: string
  imageAlt: string
  heading: any
  body: any
  isH1?: boolean
}

function Slide({
  image, imageAlt, heading, body, isH1,
}: ISlide) {
  return (
    <div>
      <img src={image} alt={imageAlt} />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="intro_layers_wrapper intro_text_bottom">
              <div className="intro_layers">
                <div className="intro_layer">
                  { isH1 ? (
                    <h1 className="intro_featured_word special-heading">
                      {heading}
                    </h1>
                  ) : (
                    <h2 className="intro_featured_word special-heading">
                      {heading}
                    </h2>
                  )}
                </div>
                <div className="intro_layer short">
                  <p className="intro_before_featured_word">
                    { body }
                  </p>
                </div>
                <div className="intro_layer button">
                  <Link className="btn btn-maincolor intro_button" to="/contact-us">get a free lesson</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Slider() {
  return (
    <Carousel autoPlay infiniteLoop showArrows interval={5000} showThumbs={false} className="page_slider">
      <Slide
        isH1
        image="/images/slider/mike-hills.jpg"
        imageAlt="Karate Ashby de la Zouch"
        heading={(
          <>
            HILLS COMBAT
            {' '}
            <br />
            SELF DEFENCE &amp;
            {' '}
            <br />
            SHOTOKAN KARATE
          </>
        )}
        body={(
          <>
            Fun &amp; interactive Karate classes for children aged 4-15
            <br />
            Private &amp; online Karate &amp; self defence tuition
          </>
        )}
      />
      <Slide
        image="/images/slider/slide01.jpg"
        imageAlt="Karate Ashby de la Zouch"
        heading={(
          <>
            Self Defence &amp;
            {' '}
            <br />
            Karate Classes
            {' '}
            <br />
            FOR CHILDREN
          </>
        )}
        body={(
          <>
            Children Karate &amp; self defence classes based in
            <br />
            Ashby de-la-Zouch, Tamworth &amp; Burntwood
          </>
        )}
      />
    </Carousel>
  );
}

Slide.defaultProps = {
  isH1: false,
};

export default Slider;
