import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function ShutoUke() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Gyakuzuki | Hills Combat</title>
      </Helmet>

      <PageHeader name="Gyakuzuki" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
        <OnlineTraining
          defaultVideo="/videos/technique/gyakuzuki.mp4"
          title="Gyakuzuki"
          subTitle="Reverse Punch"
          playlistTitle="Techniques"
          videos={[
            {
              image: '/images/members-area/technique/gyakuzuki.jpg',
              title: 'Gyakuzuki (reverse punch)',
              link: '/techniques/gyakuzuki',
            },
            {
              image: '/images/members-area/technique/mawashi-geri.jpg',
              title: 'Mawashi Geri (roundhouse kick)',
              link: '/techniques/mawashi-geri',
            },
            {
              image: '/images/members-area/technique/shuto-uke.jpg',
              title: 'Shuto Uke i Kokutsu Dachi (open hand block in back stance)',
              link: '/techniques/shuto-uke',
            },
          ]}
        >
          <>
            <p>
              Gyaku-zuki (reverse punch) is one of the basic punches. It is more powerful than
              Oi-zuki since the back hand is used to punch rather than the lead hand.
              Know that the secret to an effective gyaku-zuki is in the hip rotation.
              The power is from the hips, just like it is in throwing a ball.
            </p>

            <hr />
            <h5>Technique</h5>
            <hr />

            <p>
              <strong>1.</strong>
              {' '}
              Position yourself in Zenkutsu-dachi (Front stance)
              and ensure your legs are in the correct positions, shoulder width apart.
              Also, check that you have good balance.
            </p>

            <p>
              <strong>2.</strong>
              {' '}
              Ensure the leading hand is in front and on the same side
              as the leading leg. Check that your punching hand is nested against your hip.
            </p>

            <p>
              <strong>3.</strong>
              {' '}
              Rotate your body. Start the rotation with your hips.
              Your back leg is also adding to the force of the rotation.
              Come around quickly, leaving your fist palm facing up nested against your hip.
              Do not rise up, keep your head at the same height.
            </p>

            <p>
              <strong>4.</strong>
              {' '}
              Rotate your hand and connect with the target. Bring your fist around so
              that it faces down right before you connect.
              Hit on the center line of your target. Reverse punch using left hand or
              right hand should impact at the same place, in the center of the target.
              As you connect, lock your body for a moment as you tense up to make maximum force.
              Exhale or Kiai as you connect.
            </p>
          </>
        </OnlineTraining>
      )}
    </>
  );
}

export default ShutoUke;
