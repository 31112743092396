import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../../../../components/Loader';
import PageHeader from '../../../../components/PageHeader';
import OnlineTraining from '../../../../components/Training/Online';
import useLoginVerification from '../../../../hooks/useLoginVerification';
import Unauthorised from '../../../../components/Training/Online/Unauthorised';

function HeianShodan() {
  const { validating, loggedIn, redirectIfNotLoggedIn } = useLoginVerification();

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <>
      <Helmet>
        <title>Heian Shodan | Peaceful Mind - First Level | Hills Combat</title>
      </Helmet>

      <PageHeader name="Heian Shodan" />

      { validating && <Loader />}

      { (!validating && !loggedIn) && <Unauthorised /> }

      { !validating && loggedIn && (
        <OnlineTraining
          defaultVideo="/videos/kata/heian-shodan-front-view.mp4"
          title="Heian Shodan"
          subTitle="Peaceful Mind - First Level"
          videos={[
            {
              src: '/videos/kata/heian-shodan-front-view.mp4',
              image: '/images/members-area/kata/heian-shodan-front-view.jpg',
              title: 'Heian Shodan (front view)',
            },
            {
              src: '/videos/kata/heian-shodan-rear-view.mp4',
              image: '/images/members-area/kata/heian-shodan-rear-view.jpg',
              title: 'Heian Shodan (rear view)',
            },
          ]}
        >
          <>
            <p>
              <strong>1 - Hidari gedan-barai:</strong>
              {' '}
              Turn the head to the left and move left foot to the left in azenkutsu-dachi
              position, while doing a low blocking of the left arm (gedan-barai) and right
              hikit. The body pivots on the support foot (right), the hips rotate,
              the bust is &frac34; face.
            </p>

            <p>
              <strong>2 - Migi chudan o&iuml;-zuki:</strong>
              {' '}
              Advance the right foot on the same axis in a zenkutsu-dachiposition, while
              giving a right punch (o&iuml;-zuki) at the plexus level, left fist
              brought back in hikit&eacute;.
            </p>

            <p>
              <strong>3 - Migi gedan-barai:</strong>
              {' '}
              Swivel on the left leg 180 &deg; backwards looking over the right shoulder, arm
              fists in this direction and perform a low swept blocking (gedan-barai)
              of the right arm in zenkutsu-dachi , left fist in hikeness.
            </p>

            <p>
              <strong>4 - Migi tettsui-mawashi-uchi:</strong>
              {' '}
              Bring the right foot back, in the position renoji-dachi. At the same time,
              pull the right wrist back and strike forward vertically with a hammer
              (tettsui-uchi). The bust remains &frac34; face, the left fist remains
              in hikit&eacute;.
            </p>

            <p>
              <strong>5 - Hidari chudan o&iuml;-zuki:</strong>
              Immediately chase with a direct blow of the plexus fist
              (o&iuml;-zuki) by advancing the left foot in a zenkutsu-dachi position.
              The punch must arrive at the same time as the foot touches the ground.
            </p>

            <p>
              <strong>6 - Hidari gedan-barai:</strong>
              {' '}
              Pivot on the right foot by bringing the left foot 90 &deg; to the left in the
              central axis of the kata, inzenkutsu-dachi position . At the same time,
              firmly block down the left arm (gedan-barai). Bust 3/4 face, right
              fist in hikit&eacute;. Then on the spot, raise the left arm in blocking
              and open the hand.
            </p>

            <p>
              <strong>7- Migi jodan age-uke:</strong>
              {' '}
              Perform a high level upward blockage by raising the right arm
              (jodan age-uke), open left hand in protection in front of the forehead,
              while advancing the right foot inzenkutsu-dachi position.
              The two arms cross in front of the jaw.The bust is 3/4 face,
              the left fist is brought back in hikeness.
            </p>

            <p>
              <strong>8 - Hidari jodan age-uke:</strong>
              {' '}
              Open the right hand, advance the left foot in zenkutsu-dachi, realizing a
              blocking upward left level high (jodan age-uke). The two arms cross in
              front of the jaw. The bust is 3/4 face, the right fist is brought back in
              hikeness.
            </p>

            <p>
              <strong>9 - Migi jodan age-uke:</strong>
              Open the left hand, advance the right foot in zenkutsu-dachi , realizing
              a right ascending right lock (jodan age-uke). The two arms cross in
              front of the jaw. The bust is 3/4 face, the right fist is brought back
              in hikeness.
            </p>

            <hr />
            <p><strong>KIA&Iuml; !!!</strong></p>
            <hr />

            <p>
              <strong>10 - Hidari gedan-barai:</strong>
              {' '}
              Pivot on the right foot by bringing the left foot 90 &deg; to the left, in
              zenkutsu-dachi position. At the same time, firmly block down the left
              arm (gedan-barai). Bust 3/4 face, right fist in hikit&eacute;.
            </p>

            <p>
              <strong>11 - Migi chudan o&iuml;-zuki:</strong>
              {' '}
              Advance the right foot on the same axis in a zenkutsu-dachiposition,
              while giving a right punch at the level plexus (o&iuml;-zuki),
              left fist brought back in hikit&eacute;.
            </p>

            <p>
              <strong>12 - Migi gedan-barai:</strong>
              Look backward, turn left leg 180 &deg; backwards looking over right shoulder,
              arm well with fists in this direction in preparation for blocking, blocking
              swept lower right arm (gedan-barai) in zenkutsu-dachi , left fist in hikite.
            </p>

            <p>
              <strong>13 - Hidari chudan o&iuml;-zuki:</strong>
              {' '}
              Immediately chase with a direct blow of the plexus fist (o&iuml;-zuki)
              by advancing the left foot in a zenkutsu-dachi position.
              The punch must arrive at the same time as the foot touches the ground.
            </p>

            <p>
              <strong>14 - Hidari gedan-barai:</strong>
              {' '}
              Pivot on the right foot by bringing the left foot 90 &deg; to the left in the
              central axis of the kata, inzenkutsu-dachi position. At the same time,
              firmly block down the left arm (gedan-barai). Bust 3/4 face, right
              fist in hikit&eacute;.
            </p>

            <p>
              <strong>15 - Migi chudan o&iuml;-zuki:</strong>
              {' '}
              Advance the right foot on the same axis in a zenkutsu-dachi position,
              while giving a right punch (o&iuml;-zuki) at the plexus level,
              left fist brought back in hikit&eacute;.
            </p>

            <p>
              <strong>16 - Hidari chudan o&iuml;-zuki:</strong>
              {' '}
              Immediately chase with a direct blow of the fist (o&iuml;-zuki) to the
              plexus by advancing the left foot in a zenkutsu-dachi position.
              The punch must arrive at the same time as the foot touches the ground.
            </p>

            <p>
              <strong>17 - Migi chudan o&iuml;-zuki:</strong>
              {' '}
              Advance the right foot on the same axis in a zenkutsu-dachi position,
              while giving a right punch (o&iuml;-zuki) at the plexus level, left fist
              brought back in hikit&eacute;. Follow each other faster after the 2nd punch.
            </p>

            <hr />
            <p><strong>KIA&Iuml; !!!</strong></p>
            <hr />

            <p>
              <strong>18 - Hidari chudan shuto-uke:</strong>
              {' '}
              Pivot on the right foot by bringing the left foot 90° to the left, back
              position kokutsu-dachi. At the same time, arm the left hand open to the right
              ear, right arm extended open hand down. Block with the sword of the left
              hand level medium (shuto-uke). Bust of 3/4 face, right hand protection
              plexus level.
            </p>

            <p>
              <strong>19 - Migi chudan shuto-uke:</strong>
              {' '}
              Pivot on the left foot by bringing the right foot 45 ° to the right, back
              kokutsu-dachi position . At the same time, arm the right hand open to
              the left ear, left arm extended open hand down. Block with the sword of
              the right hand middle level (shuto-uke). Bust 3/4 face, left hand in
              plexus level protection.
            </p>

            <p>
              <strong>20 - Migi chudan shuto-uke:</strong>
              {' '}
              Swivel on the left foot by bringing the right foot 45 ° from the previous
              axis to the right, in the back position kokutsu-dachi.
              At the same time, arm the right hand open to the left ear,
              left arm extended open hand down. Block with the sword of the right
              hand middle level (shuto-uke). Bust 3/4 face, left hand in plexus
              level protection.
            </p>
            <p>
              <strong>21 - Hidari chudan shuto-uke:</strong>
              {' '}
              Pivot on the right foot by bringing the left foot 45 ° to the left,
              back position kokutsu-dachi . At the same time, arm the left hand open
              to the right ear, right arm extended open hand down. Block with the
              sword of the left hand level medium (shuto-uke). Bust of 3/4 face,
              right hand protection plexus level.
            </p>
          </>
        </OnlineTraining>
      )}
    </>
  );
}

export default HeianShodan;
