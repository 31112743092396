import React from 'react';
import { Link } from 'react-router-dom';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import { IconDefinition } from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser } from '@fortawesome/fontawesome-free-solid';

interface IAccordionItem {
  eventKey: string
  icon: IconDefinition
  name: string
  link: string
  image: string
  text: string
}

function Item({
  eventKey, icon, link, image, name, text,
}: IAccordionItem) {
  return (
    <BootstrapAccordion.Item eventKey={eventKey}>
      <BootstrapAccordion.Header className="card-header">
        <FontAwesomeIcon icon={icon as any} />
        {name}
      </BootstrapAccordion.Header>
      <BootstrapAccordion.Body className="card-body">
        <div className="media">
          <div className="media-left">
            <Link to={link}>
              <img src={image} alt={name} />
            </Link>
          </div>
          <div className="media-body">
            <p>{text}</p>
          </div>
        </div>
      </BootstrapAccordion.Body>
    </BootstrapAccordion.Item>
  );
}

function Accordion({ club }: { club?: 'ashby' | 'burntwood' | 'tamworth' }) {
  return (
    <BootstrapAccordion defaultActiveKey="0">
      { club && club === 'tamworth' && (
        <Item
          eventKey="0"
          icon={faUsers}
          name="Children's Karate Ashby"
          image="/images/gallery/group-karate-training.jpg"
          link="/childrens-karate-ashby-de-la-zouch"
          text="Not based near Tamworth? Try our Ashby de-la-Zouch karate club and learn self defence with our friendly instructors."
        />
      ) }

      { club && club === 'burntwood' && (
        <Item
          eventKey="0"
          icon={faUsers}
          name="Children's Karate Tamworth"
          image="/images/gallery/group-karate-training.jpg"
          link="/childrens-karate-tamworth"
          text="Not based near Burntwood? Try our Tamworth karate club and learn self defence with our friendly instructors."
        />
      ) }

      { club && club === 'ashby' && (
        <Item
          eventKey="0"
          icon={faUsers}
          name="Children's Karate Tamworth"
          image="/images/gallery/kids-karate-class-cta.jpg"
          link="/childrens-karate-tamworth"
          text="Not based near Ashby de-la-Zouch? Try our Tamworth karate club and learn self defence with our friendly instructors."
        />
      ) }

      { !club && (
        <>
          <Item
            eventKey="0"
            icon={faUsers}
            name="Children's Karate Ashby"
            image="/images/gallery/group-karate-training.jpg"
            link="/childrens-karate-ashby-de-la-zouch"
            text="Try our Ashby de-la-Zouch karate club and learn self defence with our friendly instructors."
          />
          <Item
            eventKey="1"
            icon={faUsers}
            name="Children's Karate Tamworth"
            image="/images/gallery/kids-karate-class-2-cta.jpg"
            link="/childrens-karate-tamworth"
            text="Try our Tamworth karate club and learn self defence with our experienced instructors."
          />
          <Item
            eventKey="2"
            icon={faUsers}
            name="Children's Karate Burntwood"
            image="/images/gallery/kids-karate-class-cta.jpg"
            link="/childrens-karate-burntwood"
            text="Try our Burntwood karate club and learn self defence with our friendly instructors."
          />
        </>
      ) }

      <Item
        eventKey="2"
        icon={faUser}
        name="Private Training"
        image="/images/gallery/private-karate-tuition-cta.jpg"
        link="/karate/private-tuition"
        text="We offer 1-2-1 karate tuition at a time to suit you. Accelerate your progress by booking a private lesson today!"
      />
    </BootstrapAccordion>
  );
}

Accordion.defaultProps = {
  club: null,
};

export default Accordion;
