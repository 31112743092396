import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/fontawesome-free-solid';

interface ITrainingItem {
  image: string
  title: string
  subTitle: string
  description: string
  link: string
}

function TrainingItem({
  image, title, subTitle, description, link,
}: ITrainingItem) {
  return (
    <div className="training-item">
      <div className="vertical-item text-center" data-index="0">
        <div className="item-media">
          <img width="370" height="230" src={image} alt={title} />
          <div className="media-links">
            <Link className="abs-link" to={link} />
          </div>
        </div>
        <Link to={link} className="services-icon">
          <FontAwesomeIcon icon={faCalendar as any} />
        </Link>
        <div className="item-content">
          <h5>
            <Link to={link}>
              {title}
            </Link>
          </h5>
          <span>{subTitle}</span>
          <p>{description}</p>
          <div className="services-link">
            <Link to={link}>
              <i
                className="fa fa-angle-down"
                aria-hidden="true"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function TrainingList() {
  return (
    <section className="ds ms s-py-60 s-py-md-90 s-overlay program-section programs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="special-heading text-center">Training &amp; Classes</h3>
            <div className="training-items">
              <TrainingItem
                link="/childrens-karate-ashby-de-la-zouch"
                image="/images/gallery/kids-karate-class-cta.jpg"
                title="Karate Ashby de-la-Zouch"
                subTitle="5:00-7:00 PM / Packington Memorial Hall"
                description="Karate & self defence classes for children 4 - 16 years old."
              />
              <TrainingItem
                link="/childrens-karate-tamworth"
                image="/images/gallery/kids-karate-tamworth-cta.jpg"
                title="Karate Tamworth"
                subTitle="7:00-8:00 PM / Newton Regis Village Hall"
                description="Martial Arts and self-defence training for Children Ages 4 to 16."
              />
              <TrainingItem
                link="/childrens-karate-burntwood"
                image="/images/gallery/group-karate-training.jpg"
                title="Karate Burntwood"
                subTitle="5:45-6:45 PM / 1st Chase Terrace Scouts Hall"
                description="Karate & self defence training for kids aged 4 to 16 Years."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrainingList;
