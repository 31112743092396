import React from 'react';

import { ThreeDots } from 'react-loader-spinner';

function Loader() {
  return (
    <section className="ds s-pt-60 s-pb-0 s-py-md-90 c-gutter-60 c-mb-40">
      <div className="container">
        <div className="row">
          <ThreeDots
            visible
            height="80"
            width="80"
            radius="9"
            color="#e43d4e"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
          />
        </div>
      </div>
    </section>
  );
}

export default Loader;
