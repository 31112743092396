import React, {
  createContext, useContext, useMemo, useState,
} from 'react';

interface IToken {
  token: string
  setToken: (token: string) => void
}

const TokenProviderContext = createContext<IToken>({ token: '', setToken: () => {} });

function TokenProvider({ children, initialTokenValue }: any) {
  const [token, setToken] = useState(
    localStorage.getItem('token') || initialTokenValue,
  );

  const storeToken = (value: string) => {
    setToken(value);
    localStorage.setItem('token', value);
  };

  const value = useMemo(() => ({ token, setToken: storeToken }), [token]);

  return (
    <TokenProviderContext.Provider
      value={value}
    >
      {children}
    </TokenProviderContext.Provider>
  );
}

export const useToken = () => useContext<IToken>(TokenProviderContext);

export default TokenProvider;
